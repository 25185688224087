import { action, autorun, computed, decorate, extendObservable } from "mobx";
import Moment from "moment";
import _ from "lodash";
import { CommonConstants } from "../../constants/CommonConstants";

const DATE_FORMAT = "M/DD/YYYY";

class EventsStore {
  constructor(authStore, commonStore, compTixApi, routerStore, loadingStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;

    this.homeAwayAllOption = { label: "All", value: { homeAwayTypeName: "all" } };
    this.allOpponentFilter = { label: "All", value: "all" };
    this.otherOpponentFilter = { label: "Other", value: "other" };
    let today = new Date();
    let thisYear = today.getFullYear();
    let minDateStr = new Date("1/1/" + thisYear);
    this.minDate = Moment(new Date(minDateStr).valueOf());

    this.defaults = {
      games: [],
      homeAwayFilter: this.homeAwayAllOption,
      opponentFilter: this.allOpponentFilter,
      ticketRequests: [],
      eventMessages: {},
      showPopover: false,
      message: ""
    };

    extendObservable(this, {
      games: this.defaults["games"],
      homeAwayFilter: this.defaults["homeAwayFilter"],
      opponentFilter: this.defaults["opponentFilter"],
      ticketRequests: this.defaults["ticketRequests"],
      eventMessages: this.defaults["eventMessages"],
      showPopover: this.defaults["showPopover"],
      message: this.defaults["message"],
      setGames: action(value => {
        this.games = value;
      }),
      setHomeAwayFilter: action(value => {
        this.homeAwayFilter = value;
      }),
      setOpponentFilter: action(value => {
        this.opponentFilter = value;
      }),
      setTicketRequests: action(values => {
        this.ticketRequests = values;
      }),
      setEventMessages: action(values => {
        this.eventMessages = values;
      }),
      setShowPopover: action(value => {
        this.showPopover = value;
      }),
      setMessage: action(value => {
        this.message = value;
      }),
      resetStore: action(() => {
        this.games = this.defaults["games"];
        this.homeAwayFilter = this.defaults["homeAwayFilter"];
        this.opponentFilter = this.defaults["opponentFilter"];
        this.eventMessages = this.defaults["eventMessages"];
        this.message = this.defaults["message"];
        this.showPopover = this.defaults["showPopover"];
      })
    });

    autorun(() => {
      if (this.routerStore.isTicketsEventsTab && this.commonStore.currentOrgId) {
        this.loadEvents();
      } else if (!(this.routerStore.isRequestTab || this.routerStore.isTransferRequestTab)) {
        this.resetStore();
      }
    });

    autorun(() => {
      if (this.routerStore.isTicketsEventsTab && this.authStore.currentUserId && this.commonStore.currentOrgId) {
        this.loadUserRequests();
      }
    });
  }

  get currentTicketRequests() {
    return this.ticketRequests.reduce((obj, item) => {
      obj[item["gamePK"]] = item;
      return obj;
    }, {});
  }

  get currentEvents() {
    if (this.games.length) {
      let events = this.games.slice();
      if (this.homeAwayFilter.value.homeAwayTypeName.toLowerCase() === "away") {
        events = events.filter(e => e.teams.away.team.name === this.commonStore.currentOrgName);
      } else if (this.homeAwayFilter.value.homeAwayTypeName.toLowerCase() === "home") {
        events = events.filter(e => e.teams.home.team.name === this.commonStore.currentOrgName);
      }

      if (this.opponentFilter.value !== this.allOpponentFilter.value) {
        events = events.filter(
          e => e.teams.home.team.id === this.opponentFilter.value || e.teams.away.team.id === this.opponentFilter.value
        );
      }

      let ticketRequests = this.currentTicketRequests;
      events.forEach((e, i) => {
        e.activeRequest =
          this.authStore.isAdmin ||
          (this.commonStore.currentTime.isAfter(Moment(e.eventAccess)) &&
            this.commonStore.currentTime.isBefore(Moment(e.eventCutoff)));
        e.passedCutoff = !this.authStore.isGlobal && this.commonStore.currentTime.isAfter(Moment(e.eventCutoff));
        let isHome = e.teams.home.team.name === this.commonStore.currentOrgName;
        e.ticketRequests = ticketRequests[e.gamePk]
          ? this.createTicketRequest(ticketRequests[e.gamePk], isHome)
          : { used: 0, allowed: 0 };
        if (this.eventMessages[e.gamePk]) {
          e.message = this.eventMessages[e.gamePk].message;
        }
      });

      return events;
    } else {
      return [];
    }
  }

  get displayCountdown() {
    return this.authStore.userData.org.orgId === this.commonStore.currentOrgId;
  }

  get homeAwayOptions() {
    return [this.homeAwayAllOption]
      .concat(this.commonStore.homeAwayOptions)
      .filter(homeAwayOption => homeAwayOption.value.homeAwayTypeName !== "Umpires");
  }

  get opponentOptions() {
    if (this.games.length) {
      let awayTeams = this.games.map(game => game.teams.away.team);
      let homeTeams = this.games.map(game => game.teams.home.team);

      let teams = awayTeams.concat(homeTeams);
      let uniqueTeams = _.uniqBy(teams, "id")
        .filter(t => {
          return t && t.name !== this.commonStore.currentOrgName;
        })
        .sort((a, b) => {
          if (!a.name || !b.name) {
            return 1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          } else {
            return -1;
          }
        });
      let options = uniqueTeams.map(t => ({ label: t.name, value: t.id }));
      options.unshift(this.allOpponentFilter);
      if (this.containsNGE(this.games)) {
        options.push(this.otherOpponentFilter);
      }
      return options;
    } else {
      return [];
    }
  }

  loadEvents() {
    let start = Moment(this.commonStore.dateRangeFilter.start);
    let end = Moment(this.commonStore.dateRangeFilter.end);

    if (start.isBefore(end)) {
      this.loadingStore.setLoading(true);
      if (this.commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID && this.authStore.userData.statsId) {
        this.compTixApi
          .getEventsForUmpire(this.authStore.userData.userId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
          .then(data => {
            this.setGames(data);
            this.loadingStore.setLoading(false);
          });
      } else {
        if (!this.authStore.isAdmin) {
          if (this.commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID && this.authStore.isBoxOfficeAdmin) {
            this.compTixApi
              .getUmpEventsByOrg(
                this.commonStore.currentOrgId,
                this.authStore.userData.org.statsId,
                start.format(DATE_FORMAT),
                end.format(DATE_FORMAT)
              )
              .then(data => {
                this.setGames(data);
                this.loadingStore.setLoading(false);
              });
            return;
          } else if (this.commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID) {
            this.setGames([]);
            this.loadingStore.setLoading(false);
            return;
          }
        }
        this.compTixApi
          .getEvents(this.commonStore.currentOrgId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
          .then(data => {
            this.setGames(data);
            this.loadingStore.setLoading(false);
          });
      }
    }
  }

  loadUserRequests() {
    let start = Moment(this.commonStore.dateRangeFilter.start);
    let end = Moment(this.commonStore.dateRangeFilter.end);
    if (start.isBefore(end)) {
      if (this.commonStore.currentOrgId === 31) {
        if (!this.authStore.userData.statsId) {
          return;
        }
        this.compTixApi
          .getTicketReservationsForUmpireByDateRange(
            this.authStore.currentUserId,
            start.format(DATE_FORMAT),
            end.format(DATE_FORMAT)
          )
          .then(ticketRequests => {
            this.loadingStore.setLoading(false);
            this.compTixApi
              .getEventMessagesForUser(this.authStore.currentUserId, ticketRequests.map(d => d.gamePK))
              .then(data => {
                this.setEventMessages(data);
                this.setTicketRequests(ticketRequests);
              });
          });
      } else {
        this.compTixApi
          .getTicketReservationsForUserByDateRange(
            this.authStore.currentUserId,
            this.commonStore.currentOrgId,
            start.format(DATE_FORMAT),
            end.format(DATE_FORMAT)
          )
          .then(ticketRequests => {
            this.compTixApi
              .getEventMessagesForUser(this.authStore.currentUserId, ticketRequests.map(d => d.gamePK))
              .then(data => {
                this.setTicketRequests(ticketRequests);
                this.setEventMessages(data);
              });
          });
      }
    }
  }

  createTicketRequest(request, isHome) {
    let used = request.totalFriendsRequests + request.totalFamilyRequests;
    let allowed = isHome
      ? request.allotment.homeFamilyAllotment + request.allotment.homeFriendsAllotment
      : request.allotment.awayFamilyAllotment + request.allotment.awayFriendsAllotment;
    allowed +=
      request.totalFamilyTransfersReceived -
      request.totalFamilyTransfersGiven +
      request.totalFriendsTransfersReceived -
      request.totalFriendsTransfersGiven;
    return { used: used, allowed: allowed };
  }

  containsNGE(events) {
    //todo: check if there is an "NGE" not sure what that is
    return false;
  }

  displayPopover(game) {
    this.setMessage(game.message);
    this.setShowPopover(true);
  }
}

decorate(EventsStore, {
  currentEvents: computed,
  currentTicketRequests: computed,
  displayCountdown: computed,
  opponentOptions: computed,
  homeAwayOptions: computed
});

export default EventsStore;
