import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import RequestDateFormatter from "../../elements/tables/RequestDateFormatter";
import { Accordion, AttributeHeader, AttributeValue, Icon, BestLink } from "best-common-react";
import AssignFormatter from "../../../utilities/AssignFormatter";

const IconHolder = styled.div.attrs({
  className: "d-flex justify-content-between align-items-center"
})``;

const Requester = styled.div.attrs({
  id: "requester"
})`
  font-size: 0.875rem;
  font-weight: 300;
`;

const Summary = styled.div.attrs({
  id: "summary"
})`
  font-size: 0.6875rem;
  color: ${props => props.theme["dark-grey"]};
`;

const Header = styled(AttributeHeader).attrs({
  id: "attribute-header"
})`
  margin-bottom: -0.25rem;
`;

const Trash = styled(Icon).attrs({ id: "trash" })``;

const Pencil = styled(Icon).attrs({ id: "pencil" })``;

class ReservationsAccordionMobile extends React.Component {
  render() {
    const { index, data, ...rest } = this.props;
    const { reservationDeleteStore, commonStore } = this.props.rootStore;

    return (
      <div>
        <Accordion id={index} {...rest}>
          <Accordion.Header>
            <IconHolder>
              <BestLink to={"/reservations/edit/" + data.id + "?from=Report"}>
                <Pencil iconName="fa-pencil-alt" className="fa-lg" />
              </BestLink>
              <Trash
                iconName="fa-trash-alt"
                className="fa-lg"
                onClick={() => {
                  reservationDeleteStore.setReservationToDelete(data.id);
                }}
              />
            </IconHolder>
          </Accordion.Header>
          <Accordion.Header isToggle={true}>
            <div>
              <Requester className="d-flex">{data.requester}</Requester>
              <Summary>
                {data.quantity} {data.relation} {data.quantity === 1 ? "ticket" : "tickets"}
                {" for "}
                {data.recipientFirstName} {data.recipientLastName}
              </Summary>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <Header>Section</Header>
              <AttributeValue>{data.sectionAndPrice}</AttributeValue>

              <Header>Pickup Location</Header>
              <AttributeValue>{data.pickupLocation}</AttributeValue>

              <Header>Department</Header>
              <AttributeValue>{data.departmentType}</AttributeValue>

              <Header>Request Date</Header>
              <AttributeValue>
                <RequestDateFormatter value={data.requestDate} />
              </AttributeValue>

              <Header>Digital Tickets</Header>
              <AttributeValue>
                <AssignFormatter value={data.id2} />
              </AttributeValue>

              <Header>Seats</Header>
              <AttributeValue>{data.seats}</AttributeValue>

              <Header>Comments</Header>
              <AttributeValue>{data.comments}</AttributeValue>
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ReservationsAccordionMobile));
