import React from "react";
import { inject, observer } from "mobx-react";
import {
  BestLabel,
  BestSelect,
  Input,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  RequiredInputWithLabel
} from "best-common-react";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";

class EditContactModal extends React.Component {
  render() {
    const { commonStore, contactManagementStore } = this.props.rootStore;
    return (
      <div>
        <Modal
          show={contactManagementStore.showEditContactModal || false}
          onClose={() => {
            contactManagementStore.setShowEditContactModal(false);
            contactManagementStore.resetStore();
          }}
          size="lg"
        >
          <ModalHeaderWithTitleAndClose
            title="Edit Contact"
            close={() => {
              contactManagementStore.setShowEditContactModal(false);
              contactManagementStore.resetStore();
            }}
          />
          <ModalBody>
            <div className="form-group">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12 form-group">
                  <RequiredInputWithLabel name="firstName" label="First Name">
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      id="firstName"
                      value={contactManagementStore.editingContact.firstName}
                      onChange={firstName => {
                        contactManagementStore.editingContact.firstName = firstName.currentTarget.value;
                      }}
                    />
                  </RequiredInputWithLabel>
                </div>
                <div className="col-lg-4 col-md-6 col-12 form-group">
                  <RequiredInputWithLabel name="lastName" label="Last Name">
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      id="lastName"
                      value={contactManagementStore.editingContact.lastName}
                      onChange={lastName => {
                        contactManagementStore.editingContact.lastName = lastName.currentTarget.value;
                      }}
                    />
                  </RequiredInputWithLabel>
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <RequiredInputWithLabel name="relation" label="Relation">
                    <BestSelect
                      id="friends-family"
                      options={commonStore.friendsAndFamilyDropdown}
                      value={contactManagementStore.editingContact.relation}
                      onChange={item => {
                        contactManagementStore.editingContact.relation = item;
                      }}
                    />
                  </RequiredInputWithLabel>
                </div>
              </div>
              <div className="secondRow row">
                <React.Fragment>
                  <div className="col-12 pb-2">Please enter a valid e-mail address or USA/Canada phone number.</div>
                  <div className="col-lg-4 col-12 form-group">
                    <BestLabel>Email</BestLabel>
                    <Input
                      type="email"
                      className="form-control"
                      id="deliveryEmail"
                      value={contactManagementStore.editingContact.email}
                      onChange={deliveryEmail => {
                        contactManagementStore.editingContact.email = deliveryEmail.currentTarget.value;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-12 form-group">
                    <BestLabel>Mobile Phone</BestLabel>
                    <PhoneInputWithCountrySelect
                      placeholder="US/Canada Only"
                      defaultCountry="US"
                      countries={["US", "CA"]}
                      international={false}
                      addInternationalOption={false}
                      countryCallingCodeEditable={false}
                      className={
                        "d-flex form-control " +
                        (contactManagementStore.editingContact.phone &&
                        isValidPhoneNumber(contactManagementStore.editingContact.phone)
                          ? ""
                          : "is-invalid")
                      }
                      onChange={value => {
                        contactManagementStore.editingContact.phone = value;
                      }}
                      value={contactManagementStore.editingContact.phone}
                    />
                  </div>
                </React.Fragment>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <PrimaryButton
                disabled={!contactManagementStore.isValidSave}
                onClick={() => contactManagementStore.saveContact()}
              >
                Edit
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  contactManagementStore.setShowEditContactModal(false);
                  contactManagementStore.resetStore();
                }}
              >
                Cancel
              </LightButton>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default inject("rootStore")(observer(EditContactModal));
