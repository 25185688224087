import React from "react";
import { CardFooter, PrimaryButton, StickyFooterButtons } from "best-common-react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";

class ContactManagementFooter extends React.Component {
  render() {
    const {
      rootStore: { contactManagementStore }
    } = this.props;
    return (
      <CardFooter>
        <StickyFooterButtons>
          <PrimaryButton
            right
            disabled={!contactManagementStore.selectedUser}
            onClick={() => {
              contactManagementStore.setSelectedContact(-1);
              contactManagementStore.setShowNewContactModal(true);
            }}
          >
            Create New Contact
          </PrimaryButton>
        </StickyFooterButtons>
      </CardFooter>
    );
  }
}

export default inject("rootStore")(observer(ContactManagementFooter));
