import { action, autorun, computed, decorate, extendObservable, reaction } from "mobx";
import _ from "lodash";
import convertUser from "./ConvertUser";
import createUserDropdown from "./CreateUserDropdown";
import { CommonConstants } from "../../constants/CommonConstants";
import validate from "validate.js";
import { isValidPhoneNumber } from "react-phone-number-input";

class MultiRequestStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      eventMessages: {},
      users: [],
      user: null,
      firstName: "",
      lastName: "",
      selectedRelation: commonStore.friendsAndFamilyDropdown[0],
      gamePks: [],
      games: [],
      deliveryEmail: "",
      deliveryMobilePhone: "",
      dialCode: 1,
      validPhone: false,
      countryCode: "",

      allotmentsAndRequests: {},
      allotments: {},
      comments: {},
      gamePK: -1,
      game: {},
      pickupLocations: {},
      sections: {},
      selectedLocations: {},
      selectedSections: {},
      quantity: { value: 0, label: "" },
      sectionInventory: [],
      selectedAllotments: {},
      alert: "",
      alerts: [],
      returnToReservations: null,
      message: null,
      isSending: false
    };

    extendObservable(this, {
      eventMessages: this.defaults["eventMessages"],
      gamePks: this.defaults["gamePks"],
      games: this.defaults["games"],
      allotmentsAndRequests: this.defaults["allotmentsAndRequests"],
      comments: this.defaults["comments"],
      gamePK: this.defaults["gamePK"],
      game: this.defaults["game"],
      firstName: this.defaults["firstName"],
      lastName: this.defaults["lastName"],
      pickupLocations: this.defaults["pickupLocations"],
      selectedSections: this.defaults["selectedSections"],
      sections: this.defaults["sections"],
      selectedLocations: this.defaults["selectedLocations"],
      quantity: this.defaults["quantity"],
      selectedRelation: this.defaults["selectedRelation"],
      sectionInventory: this.defaults["sectionInventory"],
      users: this.defaults["users"],
      user: this.defaults["user"],
      alert: this.defaults["alert"],
      alerts: this.defaults["alerts"],
      returnToReservations: this.defaults["returnToReservations"],
      message: this.defaults["message"],
      deliveryEmail: this.defaults["deliveryEmail"],
      deliveryMobilePhone: this.defaults["deliveryMobilePhone"],
      dialCode: this.defaults["dialCode"],
      validPhone: this.defaults["validPhone"],
      countryCode: this.defaults["countryCode"],
      isSending: this.defaults["isSending"],
      allotments: this.defaults["allotments"],
      selectedAllotments: this.defaults["selectedAllotments"],
      setEventMessages: action(eventMessages => {
        this.eventMessages = eventMessages;
      }),

      setGamePks: action(gamePks => {
        this.gamePks = gamePks;
      }),
      setGames: action(games => {
        this.games = games;
      }),
      setFirstName: action(firstName => {
        this.firstName = firstName;
      }),
      setLastName: action(lastName => {
        this.lastName = lastName;
      }),
      setUser: action(user => {
        this.user = user;
      }),
      setUsers: action(users => {
        this.users = users;
      }),
      setRelation: action(relation => {
        this.selectedRelation = relation;
        this.selectedSections = this.defaults["selectedSections"];
        this.quantity = this.defaults["quantity"];
      }),
      setPickupLocations: action(locations => {
        this.pickupLocations = locations;
      }),
      setSections: action(sections => {
        this.sections = sections;
      }),
      setSelectedLocations: action((gamePk, pl) => {
        let update = {};
        update[gamePk] = pl;
        let obj = Object.assign({}, this.selectedLocations);
        this.selectedLocations = Object.assign(obj, update);
      }),
      setComments: action((gamePk, comment) => {
        let update = {};
        update[gamePk] = comment;
        let obj = Object.assign({}, this.comments);
        this.comments = Object.assign(obj, update);
      }),
      setAllotmentsAndRequests: action(allotmentAndRequests => {
        this.allotmentsAndRequests = allotmentAndRequests;
      }),
      setAllotments: action(allotments => {
        this.allotments = Object.assign({}, allotments);
      }),
      setSelectedAllotments: action((gamePk, allotment) => {
        let update = {};
        update[gamePk] = allotment;
        let obj = Object.assign({}, this.selectedAllotments);
        this.selectedAllotments = Object.assign(obj, update);
      }),
      setSelectedSections: action((gamePk, section) => {
        let update = {};
        update[gamePk] = section;
        let obj = Object.assign({}, this.selectedSections);
        this.selectedSections = Object.assign(obj, update);
      }),
      setQuantity: action(quantity => {
        this.quantity = quantity;
      }),
      setSectionInventory: action(values => {
        this.sectionInventory = values;
      }),
      setReturnToReservations: action(value => {
        this.returnToReservations = value;
      }),
      setMessage: action(message => {
        this.message = message;
      }),
      setIsSending: action(value => {
        this.isSending = value;
      }),
      setDeliveryEmail: action(value => {
        this.deliveryEmail = value;
      }),
      setDeliveryMobilePhone: action((value, valid, countryInfo) => {
        this.deliveryMobilePhone = value;
        this.validPhone = value && isValidPhoneNumber(value);
      }),
      setCountryCode: action(value => {
        this.countryCode = value;
      }),
      resetStore: action(() => {
        this.eventMessages = this.defaults["eventMessages"];

        this.gamePks = this.defaults["gamePks"];
        this.game = this.defaults["game"];
        this.comments = this.defaults["comments"];
        this.user = this.defaults["user"];
        this.userId = this.defaults["userId"];
        this.firstName = this.defaults["firstName"];
        this.lastName = this.defaults["lastName"];
        this.selectedRelation = this.defaults["selectedRelation"];
        this.pickupLocations = this.defaults["pickupLocations"];
        this.selectedLocations = this.defaults["selectedLocations"];
        this.selectedAllotments = this.defaults["selectedAllotments"];
        this.selectedRelation = this.defaults["selectedRelation"];
        this.sectionInventory = this.defaults["sectionInventory"];
        this.returnToReservations = this.defaults["returnToReservations"];
        this.message = this.defaults["message"];
        this.isSending = this.defaults["isSending"];
        this.deliveryEmail = this.defaults["deliveryEmail"];
        this.deliveryMobilePhone = this.defaults["deliveryMobilePhone"];
        this.validPhone = this.defaults["validPhone"];
        this.sections = this.defaults["sections"];
        this.selectedSections = this.defaults["selectedSections"];
        this.allotments = this.defaults["allotments"];
        this.allotmentsAndRequests = this.defaults["allotmentsAndRequests"];
      }),
      saveRequests: action(() => {
        this.setIsSending(true);
        this.compTixApi.saveTicketRequests(this.ticketRequests).then(data => {
          if (data && data.alerts && data.alerts[0].severity === "ERROR" && data.alerts[0].key === "101") {
            //Refresh total available tickets for all sections
            this.getSectionInventoryByUserIdAndGamePk(this.user.userId, this.gamePK);
            return; //If error is insufficient quantity (error code 101) stay on this page
          } else {
            this.routerStore.history.push("/tickets/events");
          }
          this.setIsSending(false);
          this.resetStore();
        });
      }),
      cancel: action(() => {
        this.routerStore.history.push("/tickets/events");
        this.resetStore();
      })
    });

    autorun(() => {
      if (this.routerStore.isMultiRequestTab && this.gamePks.length > 0) {
        this.getUsersByOrg(); // user dropdown (on behalf of)
        this.getEventInformation(this.gamePks); // each game
      } else {
        this.resetStore();
      }
    });

    reaction(
      () => this.user,
      () => {
        if (this.user && routerStore.isMultiRequestTab) {
          this.compTixApi.getAllotmentAndRequestsMulti(this.gamePks, this.user.userId).then(data => {
            const obj = {};
            Object.entries(data).forEach(e => {
              obj[e[1].gamePK] = e[1];
            });
            this.setAllotments(obj);
          });

          this.getEventMessages(this.gamePks, this.user.userId);
          if (this.user && this.routerStore.isMultiRequestTab && this.gamePks.length > 0) {
            this.getSectionInventory(this.user.userId, this.gamePks);
          }
        }
      }
    );
  }

  getEventInformation() {
    if (this.gamePks.length > 0) {
      this.compTixApi
        .getInformationForEvents(this.gamePks)
        .then(data => {
          this.setGames(data);
          this.compTixApi.getPickupLocationsMulti([...new Set(data.map(t => t.venueSeasonId))].join(",")).then(locs => {
            this.setPickupLocations(locs);
          });
        })
        .catch(err => {
          this.setGames([]);
        });
    }
  }

  getUsersByOrg() {
    if (this.commonStore.currentOrgId && this.authStore.isAdmin) {
      this.compTixApi.getUsersByOrg(this.commonStore.currentOrgId, false, false).then(data => {
        let currentUserInOrg = data.filter(user => {
          return user.userId === this.authStore.userData.userId;
        });
        let sortedUsers = _.sortBy(data, [user => user.lastName.toLowerCase(), user => user.firstName.toLowerCase()]);
        let filteredUsers = _.filter(sortedUsers, user => user.active);
        this.setUsers(filteredUsers);

        if (currentUserInOrg.length) {
          this.setUser(currentUserInOrg[0]);
        } else if (filteredUsers.length) {
          this.setUser(filteredUsers[0]);
        }
      });
    } else {
      this.compTixApi.getUserById(this.authStore.currentUserId).then(data => {
        this.setUser(data);
      });
    }
  }

  getSectionInventory(userId, gamePk) {
    this.compTixApi.getSectionInventoryByUserIdAndGamePks(userId, gamePk).then(data => {
      this.setSections(data);
    });
  }

  getEventMessages(gamePks, userId) {
    this.compTixApi.getEventMessagesMulti(gamePks, userId).then(data => {
      this.setEventMessages(data);
    });
  }

  sortSections(a, b) {
    let quantityOfA = a.quantity - a.requestTotal;
    let quantityOfB = b.quantity - b.requestTotal;
    if (quantityOfA === 0 && quantityOfB !== 0) {
      return 1;
    } else if (quantityOfB === 0 && quantityOfA !== 0) {
      return -1;
    } else {
      if (a.price > b.price) {
        return 1;
      } else {
        return -1;
      }
    }
  }

  get userDropdown() {
    if (
      this.authStore.isAdmin ||
      (this.authStore.isBoxOfficeAdmin && this.commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID)
    ) {
      return createUserDropdown(this.users);
    } else {
      return [];
    }
  }

  get selectedUser() {
    return convertUser(this.user);
  }

  get pickupLocationDropdown() {
    if (Object.keys(this.pickupLocations).length > 0 && this.user) {
      const otherOrg = !!this.user.userOrgInfo ? this.user.userOrgInfo[this.commonStore.currentOrgId] : undefined;
      let departmentTypeId;
      if (this.user.department) {
        departmentTypeId = this.user.department.departmentType.departmentTypeId;
      } else {
        departmentTypeId = 0;
      }
      let dropdowns = {};
      for (const game of this.games) {
        let curPickups = this.pickupLocations[game.venueSeasonId];
        let homeAway =
          this.user.org.statsId === game.teams.home.team.id || otherOrg?.org.statsId === game.teams.home.team.id
            ? "Home"
            : this.user.org.statsId == null
            ? "Umpires"
            : "Away";

        dropdowns[game.gamePk] = _.sortBy(curPickups, "sequence")
          .filter(pl => {
            let departmentTypeIds = pl.departments.map(d => d.department.departmentTypeId);
            return _.includes(departmentTypeIds, departmentTypeId) && pl.type.homeAwayTypeName === homeAway;
          })
          .map(pl => {
            return { label: pl.pickupLocationName, value: pl };
          });
      }
      return dropdowns;
    }
    return [];
  }

  get pickupLocation() {
    if (this.selectedPickupLocation) {
      return this.selectedPickupLocation;
    }
    return this.defaults["selectedPickupLocation"];
  }

  get section() {
    if (this.selectedSection) {
      return this.selectedSection;
    }
    return this.defaults["selectedSection"];
  }

  get allotmentRemaining() {
    let allotment = 0;
    let retAllotments = {};

    if (Object.keys(this.allotments).length > 0 && this.games.length > 0 && this.user) {
      for (const game of this.games) {
        if (this.selectedRelation.value === "family") {
          if (this.user.org.statsId === game.teams.home.team.id) {
            allotment =
              this.allotments[game.gamePk].allotment.homeFamilyAllotment -
              this.allotments[game.gamePk].totalFamilyRequests +
              this.allotments[game.gamePk].totalFamilyTransfersReceived -
              this.allotments[game.gamePk].totalFamilyTransfersGiven;
          } else {
            allotment =
              this.allotments[game.gamePk].allotment.awayFamilyAllotment -
              this.allotments[game.gamePk].totalFamilyRequests +
              this.allotments[game.gamePk].totalFamilyTransfersReceived -
              this.allotments[game.gamePk].totalFamilyTransfersGiven;
          }
        } else {
          if (this.user.org.statsId === game.teams.home.team.id) {
            allotment =
              this.allotments[game.gamePk].allotment.homeFriendsAllotment -
              this.allotments[game.gamePk].totalFriendsRequests +
              this.allotments[game.gamePk].totalFriendsTransfersReceived -
              this.allotments[game.gamePk].totalFriendsTransfersGiven;
          } else {
            allotment =
              this.allotments[game.gamePk].allotment.awayFriendsAllotment -
              this.allotments[game.gamePk].totalFriendsRequests +
              this.allotments[game.gamePk].totalFriendsTransfersReceived -
              this.allotments[game.gamePk].totalFriendsTransfersGiven;
          }
        }
        retAllotments[game.gamePk] = Math.max(allotment, 0);
      }
    }
    return retAllotments;
  }

  get allotmentDropdown() {
    // Will also depend on inventory
    const dropdowns = {};
    for (const gamePk of this.gamePks) {
      let dropdown = [];

      let allotmentRemainingElement = this.allotmentRemaining[gamePk];
      for (let i = 1; i <= allotmentRemainingElement; i++) {
        dropdown.push({ value: i, label: i });
      }
      dropdowns[gamePk] = dropdown;
    }
    return dropdowns;
  }

  get inventoryRemaining() {
    return this.selectedSection
      ? Math.max(0, this.selectedSection.value.quantity - this.selectedSection.value.requestTotal)
      : 0;
  }

  get sectionInventoryOptions() {
    const retInventory = {};

    if (Object.keys(this.sections).length === 0 || !this.user) {
      return retInventory;
    }

    for (const game of this.games) {
      let section = this.sections[game.gamePk];
      retInventory[game.gamePk] = section
        .filter(si => si.relation.toLowerCase() === this.selectedRelation.value.toLowerCase())
        .filter(si => {
          const otherOrg = !!this.user.userOrgInfo ? this.user.userOrgInfo[this.commonStore.currentOrgId] : undefined;
          let homeAway =
            this.user.org.statsId === game.teams.home.team.id || otherOrg?.org.statsId === game.teams.home.team.id
              ? 1
              : 2;
          homeAway = this.user.org.orgId === 31 ? 3 : homeAway;
          return si.homeAwayTypeId === homeAway;
        })
        .sort(this.sortSections)
        .map(si => {
          let quantity = Math.max(0, si.quantity - si.requestTotal);
          let label = `${si.sectionNumber} @ $${si.price} (${quantity} left)`;
          si.left = quantity;
          return { label: label, value: si };
        });
    }
    return retInventory;
  }

  get ticketRequests() {
    let requests = [];
    for (const game of this.games) {
      let homeAway = this.user.org.statsId === game.teams.home.team.id ? 1 : 2;
      const userInfo = !!this.user.userOrgInfo ? this.user.userOrgInfo[this.commonStore.currentOrgId] : this.user;
      requests.push({
        submitterUser: { userId: this.authStore.currentUserId },
        requesterUser: { userId: this.user.userId },
        requesterDepartment: { departmentId: userInfo?.department.departmentId },
        requesterOrg: { orgId: userInfo?.org.orgId },
        homeAwayType: { homeAwayTypeId: homeAway },
        recipientFirstName: this.firstName,
        recipientLastName: this.lastName,
        relation: this.selectedRelation.value,
        section: { sectionId: this.selectedSections[game.gamePk].value.sectionId },
        quantity: this.selectedAllotments[game.gamePk].value,
        pickupLocation: { pickupLocationId: this.selectedLocations[game.gamePk].value.pickupLocationId },
        gamePk: game.gamePk,
        venueSeason: { venueSeasonId: game.venueSeasonId },
        comments: this.comments[game.gamePk],
        deliveryEmail: this.deliveryEmail,
        deliveryMobilePhone: this.deliveryMobilePhone ? this.deliveryMobilePhone.trim() : this.deliveryMobilePhone,
        dialCode: this.dialCode,
        countryCode: this.countryCode,
        isDeleted: false,
        householdType: null
      });
    }
    return requests;
  }

  get digitalDelivery() {
    if (Object.keys(this.selectedLocations).length > 0) {
      for (const key of Object.keys(this.selectedLocations)) {
        let selectedLocation = this.selectedLocations[key];

        if (selectedLocation.value.digitalDelivery) {
          return true;
        }
      }
    }
    return false;
  }

  get requireEmail() {
    if (Object.keys(this.selectedLocations).length > 0) {
      for (const key of Object.keys(this.selectedLocations)) {
        let selectedLocation = this.selectedLocations[key];
        if (selectedLocation.value.requireEmail) {
          return true;
        }
      }
    }
    return false;
  }

  get requireMobilePhone() {
    if (Object.keys(this.selectedLocations).length > 0) {
      for (const key of Object.keys(this.selectedLocations)) {
        let selectedLocation = this.selectedLocations[key];
        if (selectedLocation.value.requireMobilePhone) {
          return true;
        }
      }
    }
    return false;
  }

  get allowEitherContactMethod() {
    if (Object.keys(this.selectedLocations).length > 0) {
      for (const key of Object.keys(this.selectedLocations)) {
        let selectedLocation = this.selectedLocations[key];
        if (selectedLocation.value.allowEitherContactMethod) {
          return true;
        }
      }
    }
    return false;
  }

  get isValidRequest() {
    const validAllotments = Object.keys(this.selectedAllotments).length === this.gamePks.length;
    const validPickups = Object.keys(this.selectedLocations).length === this.gamePks.length;
    const validSections = Object.keys(this.selectedSections).length === this.gamePks.length;
    return (
      this.firstName &&
      this.firstName.length &&
      this.lastName &&
      this.lastName.length &&
      this.selectedRelation &&
      validAllotments &&
      validPickups &&
      validSections &&
      (!this.digitalDelivery ||
        !this.requireEmail ||
        !validate.validate({ from: this.deliveryEmail }, { from: { email: true } })) &&
      (!this.digitalDelivery || !this.requireMobilePhone || this.validPhone) &&
      (!this.digitalDelivery ||
        !this.allowEitherContactMethod ||
        (this.validPhone && this.deliveryMobilePhone) ||
        !validate.validate({ from: this.deliveryEmail }, { from: { email: true } }))
    );
  }
}

decorate(MultiRequestStore, {
  userDropdown: computed,
  selectedUser: computed,
  pickupLocationDropdown: computed,
  pickupLocation: computed,
  section: computed,
  allotmentRemaining: computed,
  allotmentDropdown: computed,
  inventoryRemaining: computed,
  sectionInventoryOptions: computed,
  isValidRequest: computed,
  digitalDelivery: computed,
  requireEmail: computed,
  requireMobilePhone: computed,
  allowEitherContactMethod: computed,
  ticketRequests: computed
});

export default MultiRequestStore;
