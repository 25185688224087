import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class MultiRequestFooter extends React.Component {
  render() {
    const { multiRequestStore } = this.props.rootStore;
    return (
      <StickyFooterButtons startsOpen={false}>
        <PrimaryButton
          onClick={multiRequestStore.saveRequests}
          disabled={
            !multiRequestStore.isValidRequest || multiRequestStore.isSending
            // || multiRequestStore.game.locked
          }
          id="submit-multirequest"
        >
          Submit Request
        </PrimaryButton>
        <LightButton onClick={multiRequestStore.cancel} id="cancel">
          Cancel
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(MultiRequestFooter));
