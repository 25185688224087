import { extendObservable, computed, autorun, action, decorate } from "mobx";
import _ from "lodash";
import moment from "moment";
import { getSeatAbbreviations } from "../../../utilities/TicketUtility";

class EventReservationsStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      gamePk: -1,
      game: null,
      reservations: [],
      sortFilters: {
        direction: "ASC",
        key: "requester"
      },
      searchTerm: "",
      showDeleteModal: false,
      showCopyModal: false,
      reservationToDeleteId: null,
      orgs: [],
      selectedOrg: {},
      selectedReservations: [],
      selectedDepartments: [],
      statusOptions: [
        { label: "All", value: "ALL" },
        { label: "Tickets Assigned", value: "ASSIGNED" },
        { label: "Tickets Unassigned", value: "UNASSIGNED" }
      ],
      selectedStatus: { label: "All", value: "ALL" }
    };

    extendObservable(this, {
      gamePk: this.defaults["gamePk"],
      game: this.defaults["game"],
      reservations: this.defaults["reservations"],
      sortFilters: this.defaults["sortFilters"],
      searchTerm: this.defaults["searchTerm"],
      showDeleteModal: this.defaults["showDeleteModal"],
      showCopyModal: this.defaults["showCopyModal"],
      reservationToDeleteId: this.defaults["reservationToDeleteId"],
      orgs: this.defaults["orgs"],
      selectedOrg: this.defaults["selectedOrg"],
      selectedReservations: this.defaults["selectedReservations"],
      selectedDepartments: this.defaults["selectedDepartments"],
      statusOptions: this.defaults["statusOptions"],
      selectedStatus: this.defaults["selectedStatus"],
      setReservations: action(values => {
        this.reservations = values;
      }),
      setSelectedReservations: action(values => {
        this.selectedReservations = values;
      }),
      addSelectedReservations: action(rows => {
        rows.forEach(r => (r.isSelected = true));
        this.selectedReservations = this.selectedReservations.concat(rows);
      }),
      removeSelectedReservations: action(rows => {
        rows.forEach(r => (r.isSelected = false));
        let idsToRemove = rows.map(r => r.id);
        this.selectedReservations = this.selectedReservations.filter(r => !_.includes(idsToRemove, r.id));
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm.toLowerCase();
      }),
      setDeleteModalVisibility: action(show => {
        this.showDeleteModal = show;
      }),
      setCopyModalVisibility: action(show => {
        this.showCopyModal = show;
      }),
      setReservationToDelete: action(id => {
        this.reservationToDeleteId = id;
        this.setDeleteModalVisibility(true);
      }),
      setGamePk: action(gamePk => {
        this.gamePk = gamePk;
      }),
      setGame: action(game => {
        this.game = game;
      }),
      setOrgs: action(orgs => {
        this.orgs = orgs;
      }),
      setSelectedOrg: action(org => {
        this.selectedOrg = org;
        this.setSelectedReservations([]);
      }),
      addDepartment: action(department => {
        this.selectedDepartments = department;
      }),
      addAllDepartments: action(() => {
        this.selectedDepartments = this.departmentOptions;
      }),
      setStatus: action(status => {
        this.selectedStatus = status;
      })
    });

    autorun(() => {
      if (this.routerStore.isEventReservationsTab) {
        if (this.gamePk !== -1 && this.gamePk !== null) {
          this.getReportFilters(this.authStore.userData.userId);
          this.getReservations();
        }
      } else {
        this.reset();
      }
    });
  }

  reset() {
    this.gamePk = this.defaults["gamePk"];
    this.game = this.defaults["game"];
    this.reservations = this.defaults["reservations"];
    this.sortFilters = this.defaults["sortFilters"];
    this.searchTerm = this.defaults["searchTerm"];
    this.showDeleteModal = this.defaults["showDeleteModal"];
    this.reservationToDeleteId = this.defaults["reservationToDeleteId"];
    this.orgs = this.defaults["orgs"];
    this.selectedOrg = this.defaults["selectedOrg"];
  }

  cancelReservationDelete() {
    this.setDeleteModalVisibility(false);
    this.reservationToDeleteId = null;
  }

  deleteReservation() {
    this.compTixApi.deleteReservation(this.reservationToDeleteId).then(response => {
      this.getReservations();
      this.setDeleteModalVisibility(false);
    });
  }

  cancel() {
    this.reset();
    this.routerStore.history.push("/reports/events");
  }

  addNewReservation() {
    this.routerStore.history.push("/events/" + this.gamePk + "/request?from=report");
    this.reset();
  }

  get currentReservations() {
    return this.sort(
      this.reservations
        .filter(reservation => {
          return reservation.initialDepartment.org.statsId === this.selectedOrg.value;
        })
        .map(reservation => {
          let sectionPrice = reservation.section.sectionPrice.toFixed(2);
          if (reservation.inventoryOverride && reservation.inventoryOverride.price) {
            sectionPrice = reservation.inventoryOverride.price.toFixed(2);
          }
          return {
            isSelected: _.includes(this.selectedReservations.map(r => r.id), reservation.ticketRequestId),
            id: reservation.ticketRequestId,
            id1: { id: reservation.ticketRequestId, digitalTicket: reservation.digitalTicketDelivery },
            id2: {
              requestId: reservation.ticketRequestId,
              forwardId: reservation.digitalTicketDelivery?.forwardId,
              digitalTicketDelivery: reservation.digitalTicketDelivery,
              gamePk: this.game.gamePk,
              orgId: reservation.requester
            },
            seats: getSeatAbbreviations(!reservation.digitalTicketDelivery ? "" : reservation.digitalTicketDelivery),
            accepted: reservation.forward == null ? false : reservation.forward.status === "ACCEPTED",
            game: this.game,
            requester: reservation.requester.lastName + ", " + reservation.requester.firstName,
            requesterFirst: reservation.requester.firstName,
            requesterLast: reservation.requester.lastName,
            recipient: reservation.recipientLastName + ", " + reservation.recipientFirstName,
            recipientFirstName: reservation.recipientFirstName,
            recipientLastName: reservation.recipientLastName,
            relation: _.capitalize(reservation.relation),
            sectionAndPrice: reservation.section.sectionNumber + " @ $" + sectionPrice,
            quantity: reservation.quantity,
            pickupLocation: reservation.pickupLocation.pickupLocationName,
            departmentType: reservation.initialDepartment.departmentType.departmentName,
            requestDate: { requestDate: reservation.requestDate, tzId: this.game.venue.timeZone.id },
            comments: reservation.comments,
            searchString: this.searchString(reservation),
            section: reservation.section.sectionNumber,
            price: sectionPrice,
            householdType: null
          };
        })
        .filter(reservation => {
          if (this.selectedDepartments && this.selectedDepartments.length) {
            return _.includes(
              this.selectedDepartments.map(d => d.department.departmentName.toLowerCase()),
              reservation.departmentType.toLowerCase()
            );
          } else {
            return true;
          }
        })
        .filter(reservation => {
          return _.includes(reservation.searchString, this.searchTerm);
        })
        .filter(reservation => {
          if (this.selectedStatus.value === "ALL") {
            return true;
          }
          if (this.selectedStatus.value === "ASSIGNED") {
            return reservation.id2.forwardId != null;
          }
          if (this.selectedStatus.value === "UNASSIGNED") {
            return reservation.id2.forwardId == null;
          }
          return true;
        }),
      this.sortFilters
    );
  }

  get totalReservationCount() {
    return this.reservations.filter(reservation => {
      return reservation.initialDepartment.org.statsId === this.selectedOrg.value;
    }).length;
  }

  searchString(reservation) {
    let sectionPrice = reservation.section.sectionPrice;
    if (reservation.inventoryOverride && reservation.inventoryOverride.price) {
      sectionPrice = reservation.inventoryOverride.price.toFixed(2);
    }
    return (
      _.toLower(reservation.requester.lastName + ", " + reservation.requester.firstName) +
      " " +
      _.toLower(reservation.recipientFirstName) +
      " " +
      _.toLower(reservation.recipientLastName) +
      " " +
      _.toLower(reservation.relation) +
      " " +
      _.toLower(reservation.section.sectionNumber + " @ $" + sectionPrice) +
      " " +
      reservation.quantity +
      " " +
      _.toLower(reservation.pickupLocation.pickupLocationName) +
      " " +
      _.toLower(reservation.initialDepartment.departmentType.departmentName) +
      " " +
      _.toLower(reservation.comments) +
      " " +
      moment(reservation.requestDate)
        .tz(this.game.venue.timeZone.id)
        .format("dddd M/DD - h:mma zz")
    );
  }

  get orgDropdown() {
    return this.orgs.toJSON();
  }

  get selectedDepartmentTypes() {
    return this.selectedDepartments.map(d => d.label).join(",");
  }

  sort(reservations, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(reservations, [
        reservation => _.toLower(reservation.requesterLast),
        reservation => _.toLower(reservation.requesterFirst)
      ]);
    } else if (direction === "ASC") {
      // Use _.toLower because a field may be an integer or Date object and lodash handles that nicely
      if (searchFilters.key === "requester") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.requesterLast),
          reservation => _.toLower(reservation.requesterFirst)
        ]);
      } else if (searchFilters.key === "requestDate") {
        return _.sortBy(reservations, [reservation => reservation.requestDate.requestDate]);
      } else if (searchFilters.key === "quantity") {
        return _.sortBy(reservations, ["quantity"]);
      } else if (searchFilters.key === "sectionAndPrice") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.section),
          reservation => reservation.price
        ]);
      }
      return _.sortBy(reservations, [reservation => _.toLower(reservation[searchFilters.key])]);
    } else if (direction === "DESC") {
      if (searchFilters.key === "requester") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.requesterLast),
          reservation => _.toLower(reservation.requesterFirst)
        ]).reverse();
      } else if (searchFilters.key === "requestDate") {
        return _.sortBy(reservations, [reservation => reservation.requestDate.requestDate]).reverse();
      } else if (searchFilters.key === "quantity") {
        return _.sortBy(reservations, ["quantity"]).reverse();
      } else if (searchFilters.key === "sectionAndPrice") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.section),
          reservation => reservation.price
        ]).reverse();
      }
      return _.sortBy(reservations, [reservation => _.toLower(reservation[searchFilters.key])]).reverse();
    } else {
      return reservations;
    }
  }

  buildFullReport() {
    this.compTixApi
      .sendEventReport(
        {
          gamePk: this.gamePk,
          orgId: this.selectedOrg.org.orgId,
          orgSettingsId: -1,
          direction: this.sortFilters.direction,
          key: this.sortFilters.key,
          searchTerm: this.searchTerm,
          departmentTypes: this.selectedDepartmentTypes,
          assignedFilter: this.selectedStatus.value
        },
        "full"
      )
      .then(response => {
        this.downloadLink(response, "application/vnd.ms-excel");
      });
  }

  buildBoxOfficeReport(orgSettingsId) {
    this.compTixApi
      .sendEventReport(
        {
          gamePk: this.gamePk,
          orgId: this.selectedOrg.org.orgId,
          orgSettingsId: orgSettingsId,
          direction: this.sortFilters.direction,
          key: this.sortFilters.key,
          searchTerm: this.searchTerm,
          departmentTypes: this.selectedDepartmentTypes,
          assignedFilter: this.selectedStatus.value
        },
        "boxOffice"
      )
      .then(response => {
        this.downloadLink(response, "application/pdf");
      });
  }

  buildLabelsReport(orgSettingsId) {
    this.compTixApi
      .sendEventReport(
        {
          gamePk: this.gamePk,
          orgId: this.selectedOrg.org.orgId,
          orgSettingsId: orgSettingsId,
          direction: this.sortFilters.direction,
          key: this.sortFilters.key,
          searchTerm: this.searchTerm,
          departmentTypes: this.selectedDepartmentTypes,
          assignedFilter: this.selectedStatus.value
        },
        "labels"
      )
      .then(response => {
        this.downloadLink(response, "application/pdf");
      });
  }

  buildDigitalTicketInventory() {
    this.compTixApi
      .sendDigitalTicketInventory({
        gamePk: this.gamePk,
        orgId: this.selectedOrg.org.orgId
      })
      .then(response => {
        this.downloadLink(response, "application/vnd.ms-excel");
      });
  }

  downloadLink(response, type) {
    let blob = new Blob([response.data], { type: type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, response.headers["report-title"]);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers["report-title"]);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  getReportFilters(userId) {
    this.compTixApi.getReportFilters(userId).then(data => {
      let filter = this.departmentOptions.filter(department => _.includes(data, department.label));
      this.selectedDepartments = filter;
    });
  }

  getReservations() {
    return this.compTixApi.getEventInformation(this.gamePk).then(data => {
      this.setGame(data);
      let orgs = [];
      let homeOrgs = this.commonStore.orgs.filter(o => o != null && o.statsId === data.teams.home.team.id);
      let homeOrg = homeOrgs.length ? homeOrgs[0] : null;

      let awayOrgs = data.teams.away.team
        ? this.commonStore.orgs.filter(o => o.statsId === data.teams.away.team.id)
        : [];
      let awayOrg = awayOrgs.length ? awayOrgs[0] : null;

      let home = {};
      if (homeOrg) {
        home = { label: homeOrg.orgCode, value: data.teams.home.team.id, org: homeOrg };
      }
      let away = {};
      if (data.teams.away.team) {
        away = { label: data.teams.away.team.abbreviation, value: data.teams.away.team.id, org: awayOrg };
      }

      let umpire = { label: "UMP", value: null, org: { orgId: 31 } };

      if (this.isGlobalOrHomeAdminForGame(data)) {
        if (home) {
          orgs.push(home);
        }
        if (away && away.value) {
          orgs.push(away);
        }
        orgs.push(umpire);
        if (!this.selectedOrg.label) {
          // org has already been set elsewhere
          if (this.commonStore.currentOrg.statsId === home.value) {
            this.setSelectedOrg(home);
          } else if (this.isCurrentOrgUmpire()) {
            this.setSelectedOrg(umpire);
          } else {
            if (this.commonStore.currentOrg.statsId === away.value) {
              this.setSelectedOrg(away);
            } else if (this.authStore.isGlobal) {
              this.setSelectedOrg(home);
            }
          }
        }
      } else if (this.authStore.isAdmin) {
        if (this.commonStore.currentOrg.statsId === away.value) {
          if (away.id) {
            orgs.push(away);
          }
          this.setSelectedOrg(away);
        } else if (this.isCurrentOrgUmpire()) {
          orgs.push(umpire);
          this.setSelectedOrg(umpire);
        }
      }
      this.setOrgs(orgs);
      this.compTixApi.getReservationsForGame(this.gamePk).then(data => {
        this.setReservations(data);
      });
    });
  }

  isCurrentOrgUmpire() {
    return this.commonStore.currentOrg.statsId == null;
  }

  isGlobalOrHomeAdminForGame(data) {
    return (
      this.authStore.isGlobal ||
      (this.authStore.isAdmin && data.teams.home.team.id === this.authStore.userData.org.statsId)
    );
  }

  get totals() {
    return {
      reservations: this.currentReservations.length,
      tickets: _.sum(this.currentReservations.map(r => r.quantity))
    };
  }

  get departmentOptions() {
    return this.commonStore.departmentTypes
      ? this.commonStore.departmentTypes.map(d => ({
          label: d.departmentName,
          value: d.departmentTypeId,
          department: d
        }))
      : [{}];
  }
}

decorate(EventReservationsStore, {
  currentReservations: computed,
  orgDropdown: computed,
  totalReservationCount: computed,
  departmentOptions: computed,
  totals: computed,
  selectedDepartmentTypes: computed
});

export default EventReservationsStore;
