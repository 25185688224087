import { action, autorun, computed, decorate, extendObservable, reaction, values, observable } from "mobx";
import _ from "lodash";
import convertUser from "../request/ConvertUser";
import createUserDropdown from "../request/CreateUserDropdown";
import { formatPhoneNumber } from "react-phone-number-input";

class ContactManagementStore {
  constructor(authStore, commonStore, comptixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.comptixApi = comptixApi;
    this.routerStore = routerStore;

    this.defaults = {
      contacts: [],
      users: [],
      selectedUser: null,
      selectedContact: {},
      editingContact: {},
      showNewContactModal: false,
      showEditContactModal: false,
      showDeleteContactModal: false,
      searchTerm: "",
      canSaveContact: false,
      contactSortFilters: {
        key: "lastName",
        direction: "ASC"
      }
    };

    extendObservable(this, {
      contacts: this.defaults["contacts"],
      users: this.defaults["users"],
      selectedContact: this.defaults["selectedContact"],
      editingContact: this.defaults["editingContact"],
      selectedUser: this.defaults["selectedUser"],
      searchTerm: this.defaults["searchTerm"],
      showNewContactModal: this.defaults["showNewContactModal"],
      showEditContactModal: this.defaults["showEditContactModal"],
      showDeleteContactModal: this.defaults["showDeleteContactModal"],
      contactSortFilters: this.defaults["contactSortFilters"],
      setContactSortDirection: action((col, direction) => {
        this.contactSortFilters.key = col;
        this.contactSortFilters.direction = direction;
      }),
      setSearchTerm: action(term => {
        this.searchTerm = term;
      }),
      setUsers: action(users => {
        this.users = users;
      }),
      setSelectedUser: action(user => {
        this.selectedUser = user;
      }),
      setContacts: action(contacts => {
        this.contacts = contacts;
        this.contacts.forEach(contact => {
          contact.contactId1 = contact.contactId;
          contact.formatPhone = formatPhoneNumber(contact.phone) || contact.phone;
        });
      }),
      setSelectedContact: action(contact => {
        let data = values(this.contacts).find(f => f.contactId === contact);
        this.selectedContact = data || {
          firstName: "",
          lastName: "",
          phone: "",
          relation: null,
          user: this.selectedUser
        };
        this.editingContact = Object.assign({}, this.selectedContact);
        let relation = commonStore.friendsAndFamilyDropdown.find(f => f.value === this.editingContact.relation);
        if (relation) {
          this.editingContact.relation = relation;
        }
      }),
      setShowEditContactModal: action(show => (this.showEditContactModal = show)),
      setShowNewContactModal: action(show => {
        this.showNewContactModal = show;
      }),
      setShowDeleteContactModal: action(show => (this.showDeleteContactModal = show)),
      clearSearchTerm: action(() => {
        this.searchTerm = "";
      }),
      resetStore: action(() => {
        this.editingContact = {};
      }),
      saveContact: action(() => {
        const contact = {
          firstName: this.editingContact.firstName,
          lastName: this.editingContact.lastName,
          relation: this.editingContact.relation.value,
          phone: this.editingContact.phone,
          email: this.editingContact.email,
          userId: this.editingContact.user.userId,
          contactId: this.editingContact.contactId
        };
        this.comptixApi.saveContact(contact).then(t => {
          this.setShowEditContactModal(false);
          this.setShowNewContactModal(false);
          this.refreshContacts();
        });
      }),
      deleteContact: action(() => {
        const contact = {
          firstName: this.editingContact.firstName,
          lastName: this.editingContact.lastName,
          relation: this.editingContact.relation.value,
          phone: this.editingContact.phone,
          email: this.editingContact.email,
          userId: this.editingContact.user.userId,
          contactId: this.editingContact.contactId
        };
        this.comptixApi.deleteContact(contact).then(t => {
          this.refreshContacts();
          this.setShowDeleteContactModal(false);
        });
      })
    });

    autorun(() => {
      if (this.routerStore.isContactsTab && this.commonStore.currentOrgId) {
        this.getUsersByOrg();
      }
    });

    reaction(
      () => this.selectedUser,
      () => {
        this.refreshContacts();
      }
    );
  }

  refreshContacts() {
    this.comptixApi.getContactsByUserId(this.selectedUser.userId).then(data => {
      this.setContacts(data);
    });
  }
  // Note: only one contact can be selected at a time

  sortWithFilters(sortFilters, sortData) {
    if (sortFilters["direction"] === "ASC") {
      return _.sortBy(sortData, sortFilters["key"]);
    } else if (sortFilters["direction"] === "DESC") {
      return _.sortBy(sortData, sortFilters["key"]).reverse();
    } else {
      return _.sortBy(sortData, "lastName");
    }
  }

  getUsersByOrg() {
    if (this.commonStore.currentOrgId) {
      this.comptixApi.getUsersByOrg(this.commonStore.currentOrgId, false, false).then(data => {
        let currentUserInOrg = data.filter(user => {
          return user.userId === this.authStore.userData.userId;
        });
        let sortedUsers = _.sortBy(data, [user => user.lastName.toLowerCase(), user => user.firstName.toLowerCase()]);
        let filteredUsers = _.filter(sortedUsers, user => user.active);
        this.setUsers(filteredUsers);
        if (currentUserInOrg.length) {
          this.setSelectedUser(currentUserInOrg[0]);
        } else if (filteredUsers.length) {
          this.setSelectedUser(filteredUsers[0]);
        }
      });
    }
  }

  get userDropdown() {
    return createUserDropdown(this.users);
  }

  get displayContacts() {
    return this.sortWithFilters(this.contactSortFilters, this.contacts).filter(contact => {
      return (
        contact &&
        (contact.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          contact.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          contact.relation.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          (contact.email && contact.email.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (contact.phone && contact.phone.includes(this.searchTerm)))
      );
    });
  }

  get selectedUserValue() {
    return convertUser(this.selectedUser);
  }

  get isValidSave() {
    return (
      this.editingContact.firstName &&
      this.editingContact.firstName.trim() &&
      this.editingContact.lastName &&
      this.editingContact.lastName.trim()
    );
  }
}
decorate(ContactManagementStore, {
  displayContacts: computed,
  userDropdown: computed,
  selectedUserValue: computed
});
export default ContactManagementStore;
