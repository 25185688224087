import { extendObservable, computed, autorun, action, decorate, reaction } from "mobx";
import _ from "lodash";
import moment from "moment";
import GameDateFormatter from "../../../components/elements/tables/GameDateFormatter";
import { PriceFormatter } from "best-common-react";
import RequestDateFormatter from "../../../components/elements/tables/RequestDateFormatter";
import NumberFormatter from "../../../utilities/NumberFormatter";
import { getTeamAbbreviation } from "../../../utilities/GameUtility";
import queryString from "query-string";

const DATE_FORMAT = "M/DD/YYYY";

class UserReservationsStore {
  constructor(authStore, commonStore, compTixApi, routerStore, loadingStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.loadingStore = loadingStore;

    this.defaults = {
      users: [],
      allUser: { label: "All", value: -1 },
      selectedUser: { label: "", value: -2 },
      reservations: [],
      sortFilters: {
        direction: "ASC",
        key: "date"
      },
      searchTerm: "",
      homeAwayBreakdown: { homeGames: 0, awayGames: 0 },
      selectedCategory: { label: "All", value: 3 }
    };

    extendObservable(this, {
      users: this.defaults["users"],
      allUser: this.defaults["allUser"],
      selectedUser: this.defaults["selectedUser"],
      reservations: this.defaults["reservations"],
      sortFilters: this.defaults["sortFilters"],
      searchTerm: this.defaults["searchTerm"],
      homeAwayBreakdown: this.defaults["homeAwayBreakdown"],
      selectedCategory: this.defaults["selectedCategory"],
      setReservations: action(values => {
        this.reservations = values;
      }),
      setUsers: action(values => {
        this.users = values;
      }),
      setSelectedUser: action(user => {
        this.selectedUser = user;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm;
      }),
      setHomeAwayBreakdown: action(homeAwayBreakdown => {
        this.homeAwayBreakdown = homeAwayBreakdown;
      }),
      setSelectedCategory: action(category => {
        this.selectedCategory = category;
      }),
      resetStore: action(() => {
        this.users = this.defaults["users"];
        this.selectedUser = this.defaults["selectedUser"];
        this.reservations = this.defaults["reservations"];
        this.sortFilters = this.defaults["sortFilters"];
        this.searchTerm = this.defaults["searchTerm"];
        this.selectedCategory = this.defaults["selectedCategory"];
      })
    });

    autorun(() => {
      const params = queryString.parse(this.routerStore.location.search);
      if (params["start"]) {
        this.commonStore.dateRangeFilter.start = new Date(params["start"]);
      }
      if (params["end"]) {
        this.commonStore.dateRangeFilter.end = new Date(params["end"]);
      }

      if (this.routerStore.isUserReservationsReportTab && this.commonStore.selectedOrgId) {
        if (!this.authStore.isGlobal && this.authStore.userData.userId) {
          this.selectedUser = {
            label: this.authStore.userData.lastName + ", " + this.authStore.userData.firstName,
            value: this.authStore.userData
          };
        } else {
          this.getUsers(params["userId"], params["start"], params["end"]);
        }
      } else {
        this.resetStore();
      }
    });

    reaction(
      () => this.selectedUser,
      () => {
        if (this.routerStore.isUserReservationsReportTab && this.selectedUser) {
          if (this.selectedUser.value !== -2) {
            this.getHomeAwayBreakdown();
          }
        }
      }
    );

    reaction(
      () => this.commonStore.dateRangeFilter,
      () => {
        if (this.routerStore.isUserReservationsReportTab && this.selectedUser && this.commonStore.dateRangeFilter) {
          if (this.authStore.isAdmin) {
            this.getUsers();
          }
        }
      }
    );
  }

  getReservations() {
    this.setReservations([]);
    let start = moment(this.commonStore.dateRangeFilter.start);
    let end = moment(this.commonStore.dateRangeFilter.end);
    if (this.selectedUser.value !== -1) {
      this.loadingStore.setLoading(true);
      this.compTixApi
        .getPastReservationsForUser(
          this.selectedUser.value.userId,
          this.commonStore.selectedOrgId,
          start.format(DATE_FORMAT),
          end.format(DATE_FORMAT)
        )
        .then(data => {
          this.setReservations(data);
          this.loadingStore.setLoading(false);
        });
    } else {
      this.loadingStore.setLoading(true);
      this.compTixApi
        .getPastReservationsForOrg(this.commonStore.selectedOrgId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        .then(data => {
          this.setReservations(data);
          this.loadingStore.setLoading(false);
        });
    }
  }

  getUsers(userId, startDate, endDate) {
    this.loadingStore.setLoading(true);
    let start = startDate ? moment(startDate) : moment(this.commonStore.dateRangeFilter.start);
    let end = endDate ? moment(endDate) : moment(this.commonStore.dateRangeFilter.end);
    this.compTixApi
      .getUsersForRequestDates(this.commonStore.selectedOrgId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
      .then(data => {
        this.setUsers(data);
        this.loadingStore.setLoading(false);
        //does current selected user exist in the list if so set to that
        const userIds = data.map(user => user.userId);
        if (userId && data.length > 0) {
          let v = data.find(u => parseInt(u["userId"]) === parseInt(userId));
          this.setSelectedUser({
            label: v["lastName"] + ", " + v["firstName"],
            value: v
          });
          this.getReservations();
        }
        if (this.selectedUser && !userIds.includes(this.selectedUser.value.userId) && this.authStore.isGlobal) {
          this.setSelectedUser(this.defaults["allUser"]);
        }
      });
  }

  getMaxDate() {
    return moment(new Date().valueOf());
  }

  sort(reservations, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(reservations, [reservation => reservation.date.gameDate]);
    }
    // Ascending
    else if (direction === "ASC") {
      // Use _.toLower because a field may be an integer or Date object and lodash handles that nicely
      if (searchFilters.key === "requester") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.requesterLastName),
          reservation => _.toLower(reservation.requesterFirstName)
        ]);
      } else if (searchFilters.key === "recipient") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.recipientLastName),
          reservation => _.toLower(reservation.recipientFirstName)
        ]);
      } else if (searchFilters.key === "date") {
        return _.sortBy(reservations, [reservation => reservation.date.gameDate]);
      } else if (searchFilters.key === "requestDate") {
        return _.sortBy(reservations, [reservation => reservation.requestDate.requestDate]);
      } else if (searchFilters.key === "quantity") {
        return _.sortBy(reservations, [reservation => Number(reservation.quantity)]);
      } else if (searchFilters.key === "value") {
        return _.sortBy(reservations, [reservation => Number(reservation.value)]);
      }
      return _.sortBy(reservations, [reservation => _.toLower(reservation[searchFilters.key])]);
    }
    // Descending
    else if (direction === "DESC") {
      if (searchFilters.key === "requester") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.requesterLastName),
          reservation => _.toLower(reservation.requesterFirstName)
        ]).reverse();
      } else if (searchFilters.key === "recipient") {
        return _.sortBy(reservations, [
          reservation => _.toLower(reservation.recipientLastName),
          reservation => _.toLower(reservation.recipientFirstName)
        ]).reverse();
      } else if (searchFilters.key === "date") {
        return _.sortBy(reservations, [reservation => reservation.date.gameDate]).reverse();
      } else if (searchFilters.key === "requestDate") {
        return _.sortBy(reservations, [reservation => reservation.requestDate.requestDate]).reverse();
      } else if (searchFilters.key === "quantity") {
        return _.sortBy(reservations, [reservation => Number(reservation.quantity)]).reverse();
      } else if (searchFilters.key === "value") {
        return _.sortBy(reservations, [reservation => Number(reservation.value)]).reverse();
      }
      return _.sortBy(reservations, [reservation => _.toLower(reservation[searchFilters.key])]).reverse();
    } else {
      return reservations;
    }
  }

  searchString(reservation) {
    let sectionPrice = reservation.section.sectionPrice;
    if (reservation.inventoryOverride && reservation.inventoryOverride.price) {
      sectionPrice = reservation.inventoryOverride.price.toFixed(2);
    }
    return (
      moment(reservation.game.gameDate)
        .tz(reservation.game.venue.timeZone.id)
        .format("dddd M/DD - h:mma zz") +
      " " +
      _.toLower(getTeamAbbreviation(reservation.game)) +
      " " +
      _.toLower(getTeamAbbreviation(reservation.game)) +
      " " +
      _.toLower(reservation.game.venue.name) +
      " " +
      _.toLower(reservation.recipientFirstName) +
      " " +
      _.toLower(reservation.recipientLastName) +
      " " +
      _.toLower(reservation.relation) +
      " " +
      _.toLower(reservation.section.sectionNumber + " @ $" + sectionPrice) +
      " " +
      sectionPrice * reservation.quantity +
      " " +
      reservation.quantity +
      " " +
      _.toLower(reservation.comments) +
      " " +
      moment(reservation.requestDate)
        .tz(reservation.game.venue.timeZone.id)
        .format("dddd M/DD - h:mma zz")
    );
  }

  buildUserReport() {
    let start = moment(this.commonStore.dateRangeFilter.start);
    let end = moment(this.commonStore.dateRangeFilter.end);
    this.compTixApi
      .sendUserReport(
        {
          userId: this.selectedUser.value.userId ? this.selectedUser.value.userId : -1,
          orgId: this.commonStore.selectedOrgId,
          startDate: start.format(DATE_FORMAT),
          endDate: end.format(DATE_FORMAT),
          category: this.selectedCategory.value,
          key: this.sortFilters.key,
          searchTerm: this.searchTerm,
          direction: this.sortFilters.direction
        },
        "user"
      )
      .then(response => {
        this.downloadLink(response, "application/vnd.ms-excel");
      });
  }

  downloadLink(response, type) {
    let blob = new Blob([response.data], { type: type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, response.headers["report-title"]);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers["report-title"]);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  get pastReservations() {
    return this.sort(
      this.reservations
        .filter(reservation => {
          let tz = reservation.game.venue.timeZone.id;
          let gameDate;
          if (reservation.game.status.startTimeTBD) {
            gameDate = moment(reservation.game.gameDate).utc();
          } else if (tz && tz !== -1) {
            gameDate = moment(reservation.game.gameDate).tz(tz);
          } else {
            gameDate = moment(reservation.game.gameDate);
          }
          return (
            gameDate.isSameOrAfter(this.commonStore.dateRangeFilter.start, "day") &&
            gameDate.isSameOrBefore(this.commonStore.dateRangeFilter.end, "day")
          );
        })
        .map(reservation => {
          let home = reservation.game.teams.home.team;
          let away = reservation.game.teams.away.team;
          let game = away.abbreviation + " @ " + home.abbreviation;
          let sectionPrice = reservation.section.sectionPrice.toFixed(2);
          if (reservation.inventoryOverride && reservation.inventoryOverride.price) {
            sectionPrice = reservation.inventoryOverride.price.toFixed(2);
          }
          return {
            id: reservation.ticketRequestId,
            date: {
              gameDate: reservation.game.gameDate,
              tz: reservation.game.venue.timeZone,
              tzId: reservation.game.venue.timeZone.id,
              tbd: reservation.game.status.startTimeTBD
            },
            game: game,
            venue: reservation.game.venue.name,
            requester: reservation.requester.lastName + ", " + reservation.requester.firstName,
            requesterFirstName: reservation.requester.firstName,
            requesterLastName: reservation.requester.lastName,
            recipient: reservation.recipientLastName + ", " + reservation.recipientFirstName,
            recipientFirstName: reservation.recipientFirstName,
            recipientLastName: reservation.recipientLastName,
            relation: _.capitalize(reservation.relation),
            section: reservation.section.sectionNumber + " @ $" + sectionPrice,
            value: (reservation.quantity * sectionPrice).toFixed(2),
            quantity: reservation.quantity,
            householdType: null,
            requestDate: {
              requestDate: reservation.requestDate,
              tzId: reservation.game.venue.timeZone.id,
              tz: reservation.game.venue.timeZone.tz
            },
            comments: reservation.comments,
            searchString: this.searchString(reservation)
          };
        })
        .filter(reservation => {
          return _.includes(reservation.searchString, this.searchTerm.toLowerCase());
        })
        .filter(reservation => {
          if (this.commonStore.currentOrgId !== 31) {
            return true;
          }
          if (this.selectedCategory.value === 3) {
            return true;
          }
          if (this.selectedCategory.value === 1) {
            // check advanced
            let requestDate = moment(reservation.requestDate.requestDate).tz(reservation.requestDate.tzId);
            let eventDate = moment(reservation.date.gameDate).tz(reservation.date.tzId);
            let requestPlus24 = requestDate.add(24, "hours");
            return requestPlus24.isBefore(eventDate);
          } else if (this.selectedCategory.value === 2) {
            // check convenience
            let requestDate = moment(reservation.requestDate.requestDate).tz(reservation.requestDate.tzId);
            let eventDate = moment(reservation.date.gameDate).tz(reservation.date.tzId);
            let requestPlus24 = requestDate.add(24, "hours");
            return requestPlus24.isAfter(eventDate);
          } else {
            return true;
          }
        }),
      this.sortFilters
    );
  }

  get selectedUserDisplay() {
    return this.selectedUser ? this.selectedUser : { label: "", value: -1 };
  }

  getHomeAwayBreakdown() {
    if (this.commonStore.selectedOrgId && this.commonStore.selectedOrgId !== -1) {
      let start = moment(this.commonStore.dateRangeFilter.start);
      let end = moment(this.commonStore.dateRangeFilter.end);
      if (start.isAfter(end)) {
        return;
      }
      this.compTixApi
        .getHomeAwayBreakdown(this.commonStore.selectedOrgId, start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        .then(data => {
          this.setHomeAwayBreakdown(data);
        });
    }
  }

  get userDropdown() {
    return [
      this.allUser,
      ...this.users
        .sort((a, b) => (a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0))
        .map(user => ({ label: user.lastName + ", " + user.firstName, value: user }))
    ];
  }

  get totals() {
    return {
      reservations: this.pastReservations.length,
      tickets: _.sum(this.pastReservations.map(r => r.quantity)),
      value: _.sum(this.pastReservations.map(r => parseFloat(r.value)))
    };
  }

  get cols() {
    let cols = [
      { key: "date", name: "Date", width: 200, sortable: true, formatter: GameDateFormatter, locked: true },
      { key: "game", name: "Game", width: 120, sortable: true, locked: true },
      { key: "venue", name: "Venue", width: 175, sortable: true },
      { key: "recipient", name: "Recipient", width: 140, sortable: true },
      { key: "relation", name: "Relation", width: 100, sortable: true },
      { key: "section", name: "Section", width: 200, sortable: true },
      { key: "quantity", name: "Quantity", width: 100, sortable: true, formatter: NumberFormatter },
      { key: "value", name: "Value", width: 100, sortable: true, formatter: PriceFormatter },
      { key: "requestDate", name: "Request Date", width: 220, sortable: true, formatter: RequestDateFormatter },
      { key: "comments", name: "Comments", width: 250, sortable: false }
    ];
    if (this.selectedUser === this.allUser) {
      cols.splice(3, 0, { key: "requester", name: "Requester", width: 140, sortable: true });
    }
    return cols;
  }
}

decorate(UserReservationsStore, {
  pastReservations: computed,
  userDropdown: computed,
  selectedUserDisplay: computed,
  totals: computed,
  cols: computed
});

export default UserReservationsStore;
