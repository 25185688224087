import { computed, decorate } from "mobx";
import { RouterStore } from "mobx-react-router";
import { RouteConstants } from "../constants/RouteConstants";

class CompTixRouterStore extends RouterStore {
  get isLoginTab() {
    return this.location.pathname === RouteConstants.LOGIN;
  }
  get isExamplesTab() {
    return this.location.pathname === RouteConstants.EXAMPLES;
  }
  get isUserAdminTab() {
    return this.location.pathname === RouteConstants.ADMIN_USERS;
  }
  get isUserEditTab() {
    return !!this.location.pathname.match(/\/admin\/user\/edit\/([-1-9]+)/i);
  }
  get isDepartmentAdminTab() {
    return this.location.pathname === RouteConstants.ADMIN_DEPARTMENTS;
  }
  get isDepartmentEditTab() {
    return this.location.pathname === RouteConstants.DEPARTMENT_EDIT;
  }
  get isVenueAdminTab() {
    return this.location.pathname === RouteConstants.ADMIN_VENUES;
  }
  get isVenueEditTab() {
    return this.location.pathname === RouteConstants.VENUE_EDIT;
  }
  get isVenueBulkOverrideTab() {
    return this.location.pathname === RouteConstants.VENUE_PRICES_BULK_OVERRIDE;
  }
  get isMyAccountTab() {
    return this.location.pathname === RouteConstants.MY_ACCOUNT;
  }
  get isTicketsEventsTab() {
    return this.location.pathname === RouteConstants.TICKET_EVENTS || this.location.pathname === RouteConstants.BASE;
  }
  get isReservationsTab() {
    return this.location.pathname === RouteConstants.RESERVATIONS;
  }
  get isRequestTab() {
    return !!this.location.pathname.match(/\/events\/([0-9]+)\/request/i);
  }
  get isMultiRequestTab() {
    return this.location.pathname.includes(RouteConstants.MULTI_REQUEST);
  }
  get isTransferRequestTab() {
    return !!this.location.pathname.match(/\/transfer\/([0-9]+)\/request/i);
  }
  get isEditReservationTab() {
    return !!this.location.pathname.match(/\/reservations\/edit\/([0-9]+)/i);
  }
  get isEventReservationsTab() {
    return !!this.location.pathname.match(/\/events\/([0-9]+)\/reservations/i);
  }
  get isReportEventRegistrationTab() {
    return this.location.pathname === RouteConstants.REPORT_EVENTS;
  }
  get isExportSettingsTab() {
    return this.location.pathname === RouteConstants.EXPORT_SETTINGS;
  }
  get isEventAdminTab() {
    return this.location.pathname === RouteConstants.ADMIN_EVENTS;
  }
  get isEventDetailsTab() {
    return !!this.location.pathname.match(/\/events\/([0-9]+)\/details/i);
  }
  get isUserReservationsReportTab() {
    return this.location.pathname === RouteConstants.REPORT_USERS;
  }
  get isUserSummaryTab() {
    return this.location.pathname === RouteConstants.USER_SUMMARY;
  }
  get isSystemSeasonsTab() {
    return this.location.pathname === RouteConstants.SYSTEM_SEASONS;
  }
  get isSystemSeasonEditTab() {
    return !!this.location.pathname.match(/\/system\/season\/edit\/([-1-9])/i);
  }
  get isReportHistoryTab() {
    return this.location.pathname === RouteConstants.REPORT_HISTORY;
  }
  get isPostSeasonEventsTab() {
    return this.location.pathname === RouteConstants.POSTSEASONS_EVENTS;
  }

  get isTicketSupportTab() {
    return this.location.pathname === RouteConstants.ADMIN_SUPPORT;
  }

  get isContactsTab() {
    return this.location.pathname === RouteConstants.CONTACTS;
  }

  getPathParams(path) {
    let pathSplit = path.split("/");
    let urlSplit = this.location.pathname.split("/");

    let values = {};
    pathSplit.forEach((i, index) => {
      if (i.includes(":")) {
        let key = i.substr(1);
        key = key.replace("(\\d+)", "");
        values[key] = urlSplit[index];
      }
    });
    return values;
  }
}

decorate(CompTixRouterStore, {
  isLoginTab: computed,
  isExamplesTab: computed,
  isUserAdminTab: computed,
  isUserEditTab: computed,
  isDepartmentAdminTab: computed,
  isTicketsEventsTab: computed,
  isDepartmentEditTab: computed,
  isMyAccountTab: computed,
  isVenueAdminTab: computed,
  isVenueEditTab: computed,
  isTransferRequestTab: computed,
  isReservationsTab: computed,
  isEditReservationTab: computed,
  isReportEventRegistrationTab: computed,
  isVenueBulkOverrideTab: computed,
  isExportSettingsTab: computed,
  isEventAdminTab: computed,
  isUserReservationsReportTab: computed,
  isSystemSeasonsTab: computed,
  isSystemSeasonEditTab: computed,
  isReportHistoryTab: computed
});

export default CompTixRouterStore;
