import { createBrowserHistory as createHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import CompTixApi from "../httpClients/CompTixApi";
import HttpInterceptor from "../httpClients/HttpInterceptor";
import AuthStore from "./AuthStore";
import AuthApi from "../httpClients/AuthApi";
import CompTixRouterStore from "./CompTixRouterStore";
import CommonStore from "./CommonStore";
import DepartmentAdminStore from "./department/DepartmentAdminStore";
import UserAdminStore from "./user/UserAdminStore";
import AlertStore from "./AlertStore";
import UserEditStore from "./user/UserEditStore";
import DepartmentEditStore from "./department/DepartmentEditStore";
import MyAccountStore from "./myaccount/MyAccountStore";
import ResetPasswordStore from "./myaccount/ResetPasswordStore";
import VenueAdminStore from "./venue/VenueAdminStore";
import VenueEditStore from "./venue/VenueEditStore";
import EventsStore from "./events/EventsStore";
import RequestStore from "./request/RequestStore";
import ReservationsStore from "./reservations/ReservationsStore";
import TransferRequestStore from "./request/TransferRequestStore";
import ReservationsEditStore from "./reservations/ReservationsEditStore";
import EventReservationsStore from "./reservations/reports/EventReservationsStore";
import ReportEventReservationsStore from "./reports/ReportEventReservationsStore";
import UserReservationsStore from "./user/reports/UserReservationsStore";
import ExportSettingsStore from "./reports/ExportSettingsStore";
import EventAdminStore from "./events/EventAdminStore";
import ReservationDeleteStore from "./reservations/ReservationDeleteStore";
import LoadingStore from "./loading/LoadingStore";
import GridStore from "./GridStore";
import EventDetailsStore from "./events/EventDetailsStore";
import VenueBulkOverrideStore from "./venue/VenueBulkOverrideStore";
import UserBulkAllotmentStore from "./user/UserBulkAllotmentStore";
import LoginStore from "./LoginStore";
import UserSummaryStore from "./user/reports/UserSummaryStore";
import SystemSeasonStore from "./system/SystemSeasonStore";
import SystemSeasonEditStore from "./system/SystemSeasonEditStore";
import ReportHistoryStore from "./reports/ReportHistoryStore";
import AssignDigitalTicketsStore from "./reservations/AssignDigitalTicketsStore";
import RevokeDigitalTicketsStore from "./reservations/RevokeDigitalTicketsStore";
import AdminSupportStore from "./support/AdminSupportStore";
import ContactStore from "./contacts/ContactStore";
import ContactManagementStore from "./contacts/ContactManagementStore";
import ReservationCopyStore from "./reservations/ReservationCopyStore";
import MultiRequestStore from "./request/MultiRequestStore";
import RequestSidebarStore from "./request/RequestSidebarStore";

class RootStore {
  constructor() {
    this.registerApis();
    this.registerStores();
  }

  registerApis() {
    this.compTixApi = new CompTixApi();
    this.authApi = new AuthApi();
  }

  registerStores() {
    this.routerStore = new CompTixRouterStore();
    this.history = syncHistoryWithStore(createHistory(), this.routerStore);
    this.alertStore = new AlertStore();
    this.loadingStore = new LoadingStore();
    this.authStore = new AuthStore(this.routerStore, this.authApi);
    this.loginStore = new LoginStore(this.compTixApi, this.authStore);
    this.httpInterceptor = new HttpInterceptor(this.authStore, this.alertStore);
    this.commonStore = new CommonStore(this.authStore, this.compTixApi);
    this.resetPasswordStore = new ResetPasswordStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );
    this.myAccountStore = new MyAccountStore(
      this.authStore,
      this.compTixApi,
      this.routerStore,
      this.resetPasswordStore
    );
    this.userAdminStore = new UserAdminStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.resetPasswordStore
    );
    this.userEditStore = new UserEditStore(this.authStore, this.commonStore, this.compTixApi, this.routerStore);
    this.userBulkAllotmentStore = new UserBulkAllotmentStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.userAdminStore
    );
    this.departmentAdminStore = new DepartmentAdminStore(this.commonStore, this.compTixApi, this.routerStore);
    this.departmentEditStore = new DepartmentEditStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );
    this.venueAdminStore = new VenueAdminStore(this.authStore, this.commonStore, this.compTixApi, this.routerStore);
    this.venueBulkOverrideStore = new VenueBulkOverrideStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      new GridStore(),
      this.loadingStore,
      this.alertStore
    );
    this.venueEditStore = new VenueEditStore(this.authStore, this.commonStore, this.compTixApi, this.routerStore);
    this.eventsStore = new EventsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore
    );
    this.requestStore = new RequestStore(this.authStore, this.commonStore, this.compTixApi, this.routerStore);
    this.multiRequestStore = new MultiRequestStore(this.authStore, this.commonStore, this.compTixApi, this.routerStore);
    this.reservationsStore = new ReservationsStore(this.authStore, this.commonStore, this.compTixApi, this.routerStore);
    this.reservationsEditStore = new ReservationsEditStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );
    this.eventReservationsStore = new EventReservationsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );

    this.reservationDeleteStore = new ReservationDeleteStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.eventReservationsStore
    );

    this.reservationCopyStore = new ReservationCopyStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.eventReservationsStore
    );

    this.transferRequestStore = new TransferRequestStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );
    this.userReservationsReportStore = new UserReservationsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore
    );

    this.reportEventReservationsStore = new ReportEventReservationsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore
    );

    this.assignDigitalTicketsStore = new AssignDigitalTicketsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore,
      this.alertStore,
      this.eventReservationsStore
    );

    this.revokeDigitalTicketsStore = new RevokeDigitalTicketsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore,
      this.alertStore,
      this.eventReservationsStore
    );

    this.eventAdminStore = new EventAdminStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore
    );
    this.eventDetailsStore = new EventDetailsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      new GridStore(),
      new GridStore(),
      this.alertStore
    );
    this.exportSettingsStore = new ExportSettingsStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );
    this.userSummaryStore = new UserSummaryStore(
      this.commonStore,
      this.compTixApi,
      this.routerStore,
      this.loadingStore
    );
    this.reportHistoryStore = new ReportHistoryStore(
      this.commonStore,
      this.compTixApi,
      this.loadingStore,
      this.routerStore
    );
    this.systemSeasonStore = new SystemSeasonStore(
      this.routerStore,
      this.commonStore,
      this.compTixApi,
      this.loadingStore
    );
    this.systemSeasonEditStore = new SystemSeasonEditStore(this.routerStore, this.commonStore, this.compTixApi);
    this.adminSupportStore = new AdminSupportStore(
      this.commonStore,
      this.compTixApi,
      this.loadingStore,
      this.routerStore
    );
    this.contactStore = new ContactStore(this.commonStore, this.compTixApi, this.requestStore);
    this.multiRequestContactStore = new ContactStore(this.commonStore, this.compTixApi, this.multiRequestStore);
    this.contactManagementStore = new ContactManagementStore(
      this.authStore,
      this.commonStore,
      this.compTixApi,
      this.routerStore
    );
    this.requestSidebarStore = new RequestSidebarStore(this.authStore, this.commonStore, this.routerStore);
  }
}

export default RootStore;
