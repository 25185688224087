import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, PrimaryButton, StickyFooterButtons } from "best-common-react";

class TicketEventsFooter extends React.Component {
  render() {
    const { requestSidebarStore } = this.props.rootStore;

    return (
      <StickyFooterButtons startsOpen={false}>
        <PrimaryButton
          id="requestGames"
          onClick={() => requestSidebarStore.createRequests()}
          disabled={requestSidebarStore.selectedGames.length === 0}
        >
          Create Requests
        </PrimaryButton>
      </StickyFooterButtons>
    );
  }
}

export default inject("rootStore")(observer(TicketEventsFooter));
