import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { RouteConstants } from "../../constants/RouteConstants";
import _ from "lodash";
import queryString from "query-string";
import validate from "validate.js";
import { isValidPhoneNumber } from "react-phone-number-input";

class ReservationsEditStore {
  constructor(authStore, commonStore, compTixApi, routerStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;

    this.defaults = {
      allotmentsAndRequests: {},
      reservation: {
        recipientFirstName: "",
        recipientLastName: "",
        comments: ""
      },
      from: null,
      game: {},
      originalReservation: 0,
      reservationId: -1,
      requesters: [],
      pickupLocations: [],
      sectionInventory: [],
      inventoryOverrides: new Map(),
      saveEnabled: false,
      isEdited: false,
      umpires: [],
      message: "",
      canUpdate: false
    };

    extendObservable(this, {
      allotmentsAndRequests: this.defaults["allotmentsAndRequests"],
      reservation: this.defaults["reservation"],
      from: this.defaults["from"],
      game: this.defaults["game"],
      pickupLocations: this.defaults["pickupLocations"],
      originalReservation: this.defaults["originalReservation"],
      reservationId: this.defaults["reservationId"],
      requesters: this.defaults["requesters"],
      sectionInventory: this.defaults["sectionInventory"],
      inventoryOverrides: this.defaults["inventoryOverrides"],
      saveEnabled: this.defaults["saveEnabled"],
      isEdited: this.defaults["isEdited"],
      umpires: this.defaults["umpires"],
      message: this.defaults["message"],
      canUpdate: this.defaults["canUpdate"],
      setReservationId: action(value => {
        this.reservationId = value;
      }),
      setFrom: action(value => {
        this.from = value;
      }),
      setGame: action(value => {
        this.game = value;
      }),
      setReservation: action(value => {
        this.reservation = value;
      }),
      setAllotmentsAndRequests: action(allotmentAndRequests => {
        this.allotmentsAndRequests = allotmentAndRequests;
      }),
      setOriginalReservation: action(value => {
        this.originalReservation = value;
      }),
      setPickupLocations: action(locations => {
        this.pickupLocations = locations;
      }),
      setSectionInventory: action(values => {
        this.sectionInventory = values;
      }),
      setRequesters: action(values => {
        this.requesters = values;
      }),
      setIsEdited: action(value => {
        this.isEdited = value;
      }),
      setUmpires: action(umpires => {
        this.umpires = umpires;
      }),
      setMessage: action(message => {
        this.message = message;
      }),
      calcSaveEnabled: action(() => {
        this.saveEnabled =
          this.canUpdate &&
          this.reservation.recipientFirstName &&
          this.reservation.recipientLastName &&
          this.reservation.relation &&
          this.reservation.requesterUser &&
          this.reservation.section &&
          this.reservation.pickupLocation &&
          this.reservation.quantity &&
          (!this.reservation.pickupLocation.requireEmail ||
            !validate.validate({ from: this.reservation.deliveryEmail }, { from: { email: true } })) &&
          (!this.reservation.pickupLocation.requireMobilePhone ||
            isValidPhoneNumber(this.reservation.deliveryMobilePhone)) &&
          (!this.reservation.pickupLocation.allowEitherContactMethod ||
            ((this.reservation["valid"] && this.reservation.deliveryMobilePhone) ||
              !validate.validate({ from: this.reservation.deliveryEmail }, { from: { email: true } })));
      }),
      updateValue: action((key, value, countryInfo, valid) => {
        this.setIsEdited(true);
        if (key === "requester") {
          this.reservation["quantity"] = 0;
        }
        this.reservation[key] = value;
        if (key === "deliveryMobilePhone") {
          this.reservation["valid"] = value && isValidPhoneNumber(value);
        }
        if (key === "countryCode") {
          this.reservation["countryCode"] = value;
        }
        this.calcSaveEnabled();
      }),
      resetStore: action(() => {
        this.reservation = this.defaults["reservation"];
        this.allotmentsAndRequests = this.defaults["allotmentsAndRequests"];
        this.reservation = this.defaults["reservation"];
        this.from = this.defaults["from"];
        this.game = this.defaults["game"];
        this.pickupLocations = this.defaults["pickupLocations"];
        this.originalReservation = this.defaults["originalReservation"];
        this.reservationId = this.defaults["reservationId"];
        this.requesters = this.defaults["requesters"];
        this.sectionInventory = this.defaults["sectionInventory"];
        this.isEdited = this.defaults["isEdited"];
        this.umpires = this.defaults["umpires"];
      }),
      save: action(() => {
        this.compTixApi.updateTicketRequest(this.ticketRequest).then(data => {
          if (data.alerts && data.alerts[0].severity === "ERROR" && data.alerts[0].key === "101") {
            this.getSectionInventoryByUserIdAndGamePk(this.requesterUser.userId, this.reservation.gamePk);
          } else {
            this.routerStore.history.push(this.redirectLink);
          }
        });
      }),
      cancel: action(() => {
        this.routerStore.history.push(this.redirectLink);
        this.resetStore();
      }),
      setOverrides: action(overrides => {
        this.inventoryOverrides = new Map();
        overrides.forEach(value => this.inventoryOverrides.set(value.sectionId, value));
      })
    });

    autorun(() => {
      if (this.routerStore.isEditReservationTab && this.commonStore.currentOrgId) {
        this.parseUrl();
        this.getReservation();
      } else {
        this.resetStore();
      }
    });

    autorun(() => {
      if (
        this.routerStore.isEditReservationTab &&
        this.requesterUser &&
        this.requesterUser.userId &&
        this.reservation &&
        this.reservation.gamePk
      ) {
        this.getSectionInventoryByUserIdAndGamePk(this.requesterUser.userId, this.reservation.gamePk);
        this.getAllotmentAndRequests(this.requesterUser.userId, this.reservation.gamePk);
        this.getEventMessages(this.reservation.gamePk, this.requesterUser.userId);
      }
    });
  }

  parseUrl = () => {
    let params = this.routerStore.getPathParams(RouteConstants.RESERVATIONS_EDIT);
    this.setReservationId(params.reservationId);
    let queryParams = queryString.parse(this.routerStore.location.search);
    if (queryParams.from) {
      this.setFrom(queryParams.from);
    }
  };

  getReservation = () => {
    this.compTixApi.getTicketRequest(this.reservationId).then(data => {
      this.setReservation(data);
      this.setOriginalReservation(data);
      this.getGame(this.reservation.gamePk);
      this.getUsersByOrg();
      this.canUpdate = true;
    });
    this.compTixApi.getTicketRequestDTO(this.reservationId).then(data => {
      this.canUpdate = !(data.digitalTickets.length > 0);
    });
  };

  getGame = gamePk => {
    this.compTixApi.getEventInformation(gamePk).then(data => {
      this.setGame(data);
      this.setUmpires(data.umpires);
      this.compTixApi.getPickupLocations(data.venueSeasonId).then(data => {
        this.setPickupLocations(data);
      });
    });
  };

  getSectionInventoryByUserIdAndGamePk = (userId, gamePk) => {
    this.compTixApi.getSectionInventoryByUserIdAndGamePk(userId, gamePk).then(data => {
      this.compTixApi.getInventoryOverride(gamePk).then(overrides => {
        this.setOverrides(overrides);
        this.setSectionInventory(data);
      });
    });
  };

  getAllotmentAndRequests = (userId, gamePk) => {
    this.compTixApi.getAllotmentAndRequests(gamePk, userId).then(data => {
      this.setAllotmentsAndRequests(data);
    });
  };

  getUsersByOrg = () => {
    if (this.requesterUser) {
      this.compTixApi.getUsersByOrg(this.reservation.requesterOrg.orgId, false, false).then(data => {
        this.setRequesters(_.filter(data, user => user.active));
      });
    }
  };

  getEventMessages(gamePk, userId) {
    this.compTixApi.getEventMessageForUser(gamePk, userId).then(data => {
      if (data.length) {
        this.setMessage(data[0].message);
      }
    });
  }

  sortSections(a, b) {
    let quantityOfA = a.quantity - a.requestTotal;
    let quantityOfB = b.quantity - b.requestTotal;
    if (quantityOfA === 0 && quantityOfB !== 0) {
      return 1;
    } else if (quantityOfB === 0 && quantityOfA !== 0) {
      return -1;
    } else {
      if (a.price > b.price) {
        return 1;
      } else {
        return -1;
      }
    }
  }

  get allotmentOptions() {
    let dropdown = [];
    let quantity = this.isFromReport
      ? this.inventoryRemaining + this.originalReservation.quantity - (this.currentSection.requestTotal || 0)
      : Math.max(Math.min(this.allotmentRemaining, this.inventoryRemaining), this.originalReservation.quantity);
    for (let i = 1; i <= quantity; i++) {
      dropdown.push({ value: i, label: i });
    }
    return dropdown;
  }

  get allotmentRemaining() {
    let allotment = 0;
    if (
      this.reservation.relation &&
      this.allotmentsAndRequests.allotment &&
      this.requesterUser.org &&
      this.game.teams
    ) {
      if (this.reservation.relation === "family") {
        if (this.requesterUser.org.statsId === this.game.teams.home.team.id) {
          allotment =
            this.allotmentsAndRequests.allotment.homeFamilyAllotment -
            this.allotmentsAndRequests.totalFamilyRequests +
            this.allotmentsAndRequests.totalFamilyTransfersReceived -
            this.allotmentsAndRequests.totalFamilyTransfersGiven;
        } else {
          allotment =
            this.allotmentsAndRequests.allotment.awayFamilyAllotment -
            this.allotmentsAndRequests.totalFamilyRequests +
            this.allotmentsAndRequests.totalFamilyTransfersReceived -
            this.allotmentsAndRequests.totalFamilyTransfersGiven;
        }
      } else {
        if (this.requesterUser.org.statsId === this.game.teams.home.team.id) {
          allotment =
            this.allotmentsAndRequests.allotment.homeFriendsAllotment -
            this.allotmentsAndRequests.totalFriendsRequests +
            this.allotmentsAndRequests.totalFriendsTransfersReceived -
            this.allotmentsAndRequests.totalFriendsTransfersGiven;
        } else {
          allotment =
            this.allotmentsAndRequests.allotment.awayFriendsAllotment -
            this.allotmentsAndRequests.totalFriendsRequests +
            this.allotmentsAndRequests.totalFriendsTransfersReceived -
            this.allotmentsAndRequests.totalFriendsTransfersGiven;
        }
      }
      if (this.requesterUser.userId === this.originalReservation.requesterUser.userId) {
        allotment += this.originalReservation.quantity;
      }
    }
    return Math.max(allotment, 0);
  }

  get allotmentDropdownValue() {
    return this.reservation && this.reservation.quantity
      ? this.allotmentOptions.find(ao => ao.value === this.reservation.quantity)
      : null;
  }

  get currentGame() {
    if (this.game) {
      return this.game;
    } else {
      return {};
    }
  }

  get currentSection() {
    if (
      this.sectionInventory &&
      this.sectionInventory.length &&
      this.originalReservation &&
      this.originalReservation.section
    ) {
      return this.sectionInventory.find(si => si.sectionId === this.reservation.section.sectionId) || {};
    } else {
      return {};
    }
  }

  get displayRequester() {
    return this.authStore.isAdmin && this.isFromReport;
  }

  get isFromReport() {
    return this.from && this.from.toLowerCase() === "report";
  }

  get inventoryRemaining() {
    if (this.reservation && this.reservation.section) {
      if (
        this.inventoryOverrides.get(this.reservation.section.sectionId) &&
        this.inventoryOverrides.get(this.reservation.section.sectionId).quantity != null
      ) {
        return this.inventoryOverrides.get(this.reservation.section.sectionId).quantity;
      }
      if (this.reservation.section.inventoryQuantity) {
        return this.reservation.section.inventoryQuantity;
      } else {
        return this.reservation.section.quantity;
      }
    } else {
      return 0;
    }
  }

  get pickupLocationOptions() {
    if (this.pickupLocations.length && this.requesterUser) {
      let homeAway = this.reservation.requesterOrg.statsId === this.game.teams.home.team.id ? "Home" : "Away";
      if (this.requesterUser.org.statsId == null) {
        homeAway = "Umpires";
      }
      let departmentTypeId;
      if (this.requesterUser.department) {
        departmentTypeId = this.requesterUser.department.departmentType.departmentTypeId;
      } else {
        departmentTypeId = 0;
      }

      return _.sortBy(this.pickupLocations, "sequence")
        .filter(pl => {
          let departmentTypeIds = pl.departments.map(d => d.department.departmentTypeId);
          return _.includes(departmentTypeIds, departmentTypeId) && pl.type.homeAwayTypeName === homeAway;
        })
        .map(pl => {
          return { label: pl.pickupLocationName, value: pl };
        });
    }
    return [];
  }

  get pickupLocationDropdownValue() {
    return this.pickupLocations && this.reservation.pickupLocation
      ? this.pickupLocationOptions.find(
          pl => pl.value.pickupLocationId === this.reservation.pickupLocation.pickupLocationId
        )
      : null;
  }

  get relationDropdownValue() {
    return this.reservation.relation
      ? this.commonStore.friendsAndFamilyDropdown.find(
          option => option.value.toLowerCase() === this.reservation.relation.toLowerCase()
        )
      : null;
  }

  get requesterUser() {
    return this.displayRequester ? this.reservation.requesterUser : this.authStore.userData;
  }

  get requesterOptions() {
    let options = this.commonStore.currentOrg !== 31 ? this.requesters : this.umpires;

    return options
      .sort((a, b) => {
        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      })
      .map(r => ({
        label: r.lastName + ", " + r.firstName,
        value: r
      }));
  }

  get requesterDropdownValue() {
    return this.reservation.requesterUser && this.requesterOptions.length
      ? this.requesterOptions.find(option => option.value.userId === this.reservation.requesterUser.userId)
      : {};
  }

  get sectionInventoryOptions() {
    return this.relationDropdownValue && this.game && this.game.teams
      ? this.sectionInventory
          .filter(si => si.relation.toLowerCase() === this.relationDropdownValue.value.toLowerCase())
          .filter(si => {
            let homeAway = this.reservation.requesterOrg.statsId === this.game.teams.home.team.id ? 1 : 2;
            homeAway = this.requesterUser.org.orgId === 31 ? 3 : homeAway;
            return si.homeAwayTypeId === homeAway;
          })
          .sort(this.sortSections)
          .map(si => {
            let price = si.price;
            if (this.inventoryOverrides.get(si.sectionId) && this.inventoryOverrides.get(si.sectionId).price) {
              price = this.inventoryOverrides.get(si.sectionId).price;
            }
            let totalQuantity = si.quantity;
            if (this.inventoryOverrides.get(si.sectionId) && this.inventoryOverrides.get(si.sectionId).quantity) {
              totalQuantity = this.inventoryOverrides.get(si.sectionId).quantity;
            }

            let isCurrentSection = si.sectionId === this.originalReservation.section.sectionId;
            let sectionQuantity = isCurrentSection ? this.originalReservation.quantity : 0;
            let quantity = Math.max(0, totalQuantity - si.requestTotal + sectionQuantity);
            let label = si.sectionNumber + " @ $" + price + "         " + quantity + " left";
            si.left = quantity;
            return { label: label, value: si };
          })
      : [];
  }

  get sectionInventoryDropdownValue() {
    return this.sectionInventoryOptions
      ? this.sectionInventoryOptions.find(sio => sio.value.sectionId === this.reservation.section.sectionId) || null
      : null;
  }

  get ticketRequest() {
    let department =
      this.originalReservation.requesterUser.userId === this.reservation.requesterUser.userId
        ? this.reservation.requesterDepartment
        : this.reservation.requesterUser.department;
    let org =
      this.originalReservation.requesterUser.userId === this.reservation.requesterUser.userId
        ? this.reservation.requesterOrg
        : this.reservation.requesterUser.org;

    return {
      ticketRequestId: this.reservation.ticketRequestId,
      submitterUser: { userId: this.reservation.submitterUser.userId },
      requesterUser: { userId: this.reservation.requesterUser.userId },
      requesterDepartment: { departmentId: department.departmentId },
      requesterOrg: { orgId: org.orgId },
      homeAwayType: { homeAwayTypeId: this.reservation.homeAwayType.homeAwayTypeId },
      recipientFirstName: this.reservation.recipientFirstName,
      recipientLastName: this.reservation.recipientLastName,
      relation: this.reservation.relation,
      section: { sectionId: this.reservation.section.sectionId },
      quantity: this.reservation.quantity,
      pickupLocation: { pickupLocationId: this.reservation.pickupLocation.pickupLocationId },
      gamePk: this.reservation.gamePk,
      comments: this.reservation.comments,
      isDeleted: this.reservation.isDeleted,
      submitDate: this.reservation.submitDate,
      venueSeason: this.reservation.venueSeason,
      deliveryEmail: this.reservation.deliveryEmail,
      deliveryMobilePhone: this.reservation.deliveryMobilePhone
    };
  }

  get redirectLink() {
    if (this.isFromReport) {
      return "/events/" + this.reservation.gamePk + "/reservations";
    } else {
      return "/reservations";
    }
  }
}

decorate(ReservationsEditStore, {
  allotmentRemaining: computed,
  allotmentOptions: computed,
  allotmentDropdownValue: computed,
  currentGame: computed,
  currentSection: computed,
  displayRequester: computed,
  inventoryRemaining: computed,
  isFromReport: computed,
  pickupLocationOptions: computed,
  pickupLocationDropdownValue: computed,
  requesterUser: computed,
  requesterOptions: computed,
  requesterDropdownValue: computed,
  relationDropdownValue: computed,
  sectionInventoryOptions: computed,
  sectionInventoryDropdownValue: computed,
  ticketRequest: computed,
  redirectLink: computed
});

export default ReservationsEditStore;
