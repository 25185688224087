import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { components } from "react-select";
import {
  BestLabel,
  BestSelect,
  RequiredInputWithLabel,
  InfoCard,
  Icon,
  Input,
  RequiredIndicator,
  ButtonLink,
  DesktopTabletView,
  MobileView
} from "best-common-react";
import { CommonConstants } from "../../../constants/CommonConstants";
import NewContactModal from "../../contacts/NewContactModal";
import SelectContactModal from "../../contacts/SelectContactModal";
import EventDataCard from "../../events/EventDataCard";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";

const CommentInput = styled.textarea.attrs({ maxLength: "150", id: "comment-text-area" })`
  height: 5em;
`;

const WarningIcon = styled(Icon).attrs({ className: "mr-2" })`
  &&& {
    color: #ff9100;
  }
`;

const Information = styled.div.attrs({ className: "mt-2" })`
  &&& {
    color: #ff9100;
  }
`;

const SectionOption = ({ data, ...rest }) => (
  <components.Option {...rest}>
    {data && data.value && (
      <div className="d-flex justify-content-between">
        <div>
          {data.value.sectionNumber} @ ${data.value.price}
        </div>
        <div>{data.value.left} left</div>
      </div>
    )}
  </components.Option>
);

const SectionValue = ({ data, ...rest }) => (
  <components.ValueContainer {...rest}>
    {data && data.value && (
      <div>
        <span className="mr-3">
          {data.value.sectionNumber} @ ${data.value.price}
        </span>
        <span>{data.value.left} left</span>
      </div>
    )}
  </components.ValueContainer>
);

class MultiRequestFilters extends React.Component {
  render() {
    const { multiRequestStore, commonStore, authStore, multiRequestContactStore } = this.props.rootStore;
    return (
      <>
        {!!multiRequestContactStore.showNewContactModal && (
          <NewContactModal requestStore={multiRequestStore} contactStore={multiRequestContactStore} />
        )}
        {!!multiRequestContactStore.showSelectContactModal && (
          <SelectContactModal contactStore={multiRequestContactStore} />
        )}
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div
                hidden={
                  !authStore.isAdmin &&
                  !(authStore.isBoxOfficeAdmin && commonStore.currentOrgId === CommonConstants.KEYS.UMP_ID)
                }
                className="col col-lg-3 col-12 form-group"
              >
                <BestLabel htmlFor="on-behalf-of">Request On Behalf Of</BestLabel>
                <BestSelect
                  id="on-behalf-of"
                  options={multiRequestStore.userDropdown}
                  value={multiRequestStore.selectedUser}
                  onChange={item => multiRequestStore.setUser(item.value)}
                  isSearchable={true}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12 form-group">
                <div className="d-flex justify-content-between align-items-end">
                  <BestLabel htmlFor="firstName">
                    First Name <RequiredIndicator />
                  </BestLabel>
                  <ButtonLink
                    style={{ fontSize: "0.875rem", padding: "0px" }}
                    onClick={() => multiRequestContactStore.setShowSelectContactModal(true)}
                  >
                    Select Contact
                  </ButtonLink>
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  id="firstName"
                  value={multiRequestStore.firstName}
                  onChange={firstName => multiRequestStore.setFirstName(firstName.target.value)}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12 form-group">
                <RequiredInputWithLabel name="lastName" label="Last Name" htmlFor="lastName">
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    value={multiRequestStore.lastName}
                    onChange={lastName => multiRequestStore.setLastName(lastName.target.value)}
                  />
                </RequiredInputWithLabel>
              </div>
              <div className="col-lg-3 col-12 form-group">
                <RequiredInputWithLabel name="relation" label="Relation" htmlFor="friends-family">
                  <BestSelect
                    id="friends-family"
                    options={commonStore.friendsAndFamilyDropdown}
                    value={multiRequestStore.selectedRelation}
                    onChange={item => multiRequestStore.setRelation(item)}
                  />
                </RequiredInputWithLabel>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {multiRequestStore.digitalDelivery ? (
            <DesktopTabletView>
              <React.Fragment>
                <div className="col-lg-4 col-12 form-group">
                  {multiRequestStore.requireEmail ? (
                    <RequiredInputWithLabel name="deliveryEmail" label="Email" htmlFor="delivery-email">
                      <Input
                        type="email"
                        className="form-control"
                        id="delivery-email"
                        value={multiRequestStore.deliveryEmail}
                        onChange={deliveryEmail => multiRequestStore.setDeliveryEmail(deliveryEmail.target.value)}
                      />
                    </RequiredInputWithLabel>
                  ) : null}
                </div>
                <div className="col-lg-4 col-12 form-group">
                  {multiRequestStore.requireMobilePhone ? (
                    <RequiredInputWithLabel name="deliveryMobilePhone" label="Mobile Phone" htmlFor="intl-tel-input-2">
                      <PhoneInputWithCountrySelect
                        placeholder="US/Canada Only"
                        defaultCountry={multiRequestStore.countryCode || "US"}
                        countries={["US", "CA"]}
                        international={false}
                        addInternationalOption={false}
                        countryCallingCodeEditable={false}
                        className={
                          "d-flex form-control " +
                          (multiRequestStore.deliveryMobilePhone &&
                          isValidPhoneNumber(multiRequestStore.deliveryMobilePhone)
                            ? ""
                            : "is-invalid")
                        }
                        onChange={value => {
                          multiRequestStore.setDeliveryMobilePhone(value);
                        }}
                        onCountryChange={value => {
                          multiRequestStore.setCountryCode(value);
                        }}
                        value={multiRequestStore.deliveryMobilePhone}
                      />
                    </RequiredInputWithLabel>
                  ) : null}
                </div>
                <div className="col-lg-4 col-12 mr-4 mb-2">
                  <ButtonLink
                    style={{ fontSize: "0.875rem", padding: "0px" }}
                    onClick={() => multiRequestContactStore.setShowNewContactModal(true)}
                  >
                    Save Contact
                  </ButtonLink>
                </div>
              </React.Fragment>
            </DesktopTabletView>
          ) : null}
          {multiRequestStore.allowEitherContactMethod ? (
            <DesktopTabletView>
              <React.Fragment>
                <div className="col-12 pb-2">Please enter a valid e-mail address or USA/Canada phone number.</div>
                <div className="col-lg-4 col-12 form-group">
                  <BestLabel htmlFor="delivery-email">Email</BestLabel>
                  <Input
                    type="email"
                    className="form-control"
                    id="delivery-email"
                    value={multiRequestStore.deliveryEmail}
                    onChange={deliveryEmail => multiRequestStore.setDeliveryEmail(deliveryEmail.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <BestLabel htmlFor="intl-tel-input-1">Mobile Phone</BestLabel>
                  <PhoneInputWithCountrySelect
                    id="intl-tel-input-1"
                    placeholder="US/Canada Only"
                    defaultCountry={multiRequestStore.countryCode || "US"}
                    countries={["US", "CA"]}
                    international={false}
                    addInternationalOption={false}
                    countryCallingCodeEditable={false}
                    className={
                      "d-flex form-control " +
                      (multiRequestStore.deliveryMobilePhone &&
                      isValidPhoneNumber(multiRequestStore.deliveryMobilePhone)
                        ? ""
                        : "is-invalid")
                    }
                    onChange={value => {
                      multiRequestStore.setDeliveryMobilePhone(value);
                    }}
                    onCountryChange={value => {
                      multiRequestStore.setCountryCode(value);
                    }}
                    value={multiRequestStore.deliveryMobilePhone}
                  />
                </div>
                <div className="col-lg-4 col-12 mr-4 mb-2">
                  <ButtonLink
                    style={{ fontSize: "0.875rem", padding: "0px" }}
                    onClick={() => multiRequestContactStore.setShowNewContactModal(true)}
                  >
                    Save Contact
                  </ButtonLink>
                </div>
              </React.Fragment>
            </DesktopTabletView>
          ) : null}
        </div>
        {multiRequestStore.games.map((t, index) => (
          <div className="row" key={"game-info" + index}>
            <div className="col-lg-3 col-12">
              <EventDataCard game={t} displayCountdown={true} showVenue={true} />
            </div>
            <div className="col-lg-9 col-12">
              <InfoCard backgroundColor="white" className="my-2" title="">
                <div className="col">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <RequiredInputWithLabel
                        name="pickupLocation"
                        label="Pickup Location"
                        htmlFor={"pickup-location" + index}
                      >
                        <BestSelect
                          id={"pickup-location" + index}
                          options={multiRequestStore.pickupLocationDropdown[t.gamePk] || []}
                          value={multiRequestStore.selectedLocations[t.gamePk] || {}}
                          onChange={item => {
                            multiRequestStore.setSelectedLocations(t.gamePk, item);
                          }}
                        />
                      </RequiredInputWithLabel>
                    </div>
                    <div className="col-lg-4 col-6">
                      <RequiredInputWithLabel
                        name="availableSections"
                        label="Available Sections"
                        htmlFor={"sections" + index}
                      >
                        <BestSelect
                          id={"sections" + index}
                          options={multiRequestStore.sectionInventoryOptions[t.gamePk] || []}
                          value={multiRequestStore.selectedSections[t.gamePk] || {}}
                          onChange={item => {
                            multiRequestStore.setSelectedSections(t.gamePk, item);
                          }}
                          components={{ Option: SectionOption, SingleValue: SectionValue }}
                          placeholder=""
                        />
                      </RequiredInputWithLabel>
                    </div>
                    <div className="col-lg-4 col-6">
                      <RequiredInputWithLabel
                        name="quantity"
                        label="Quantity"
                        rightLabel={
                          multiRequestStore.allotmentRemaining[t.gamePk] === undefined
                            ? ""
                            : multiRequestStore.allotmentRemaining[t.gamePk] === 1
                            ? multiRequestStore.allotmentRemaining[t.gamePk] + " ticket remaining"
                            : multiRequestStore.allotmentRemaining[t.gamePk] + " tickets remaining"
                        }
                        htmlFor={"allotment" + index}
                      >
                        <BestSelect
                          id={"allotment" + index}
                          options={multiRequestStore.allotmentDropdown[t.gamePk] || {}}
                          value={multiRequestStore.selectedAllotments[t.gamePk] || {}}
                          onChange={item => {
                            multiRequestStore.setSelectedAllotments(t.gamePk, item);
                          }}
                          placeholder=""
                        />
                      </RequiredInputWithLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8 form-group mt-3">
                      <BestLabel htmlFor={"comments" + index}>Comments</BestLabel>
                      <CommentInput
                        className="form-control"
                        name="comments"
                        value={multiRequestStore.comments[t.gamePk] || ""}
                        onChange={v => multiRequestStore.setComments(t.gamePk, v.target.value)}
                        id={"comments" + t.gamePk}
                      />
                    </div>
                    <div className="col-4">
                      {multiRequestStore.eventMessages[t.gamePk]?.message ? (
                        <Information id={"message" + index}>
                          <span>
                            <WarningIcon iconName="fa-info-circle" />
                          </span>
                          {multiRequestStore.eventMessages[t.gamePk]?.message}
                        </Information>
                      ) : null}
                    </div>
                  </div>
                </div>
              </InfoCard>
            </div>
          </div>
        ))}
        <div className="row">
          {multiRequestStore.digitalDelivery ? (
            <MobileView>
              <React.Fragment>
                <div className="col-lg-4 col-12 form-group">
                  {multiRequestStore.requireEmail ? (
                    <RequiredInputWithLabel name="deliveryEmail" label="Email" htmlFor="delivery-email">
                      <Input
                        type="email"
                        className="form-control"
                        id="delivery-email"
                        value={multiRequestStore.deliveryEmail}
                        onChange={deliveryEmail => multiRequestStore.setDeliveryEmail(deliveryEmail.target.value)}
                      />
                    </RequiredInputWithLabel>
                  ) : null}
                </div>
                <div className="col-12 form-group">
                  {multiRequestStore.requireMobilePhone ? (
                    <RequiredInputWithLabel name="deliveryMobilePhone" label="Mobile Phone" htmlFor="intl-tel-input-2">
                      <PhoneInputWithCountrySelect
                        id="intl-tel-input-2"
                        placeholder="US/Canada Only"
                        defaultCountry={multiRequestStore.countryCode || "US"}
                        countries={["US", "CA"]}
                        international={false}
                        addInternationalOption={false}
                        countryCallingCodeEditable={false}
                        className={
                          "d-flex form-control " +
                          (multiRequestStore.deliveryMobilePhone &&
                          isValidPhoneNumber(multiRequestStore.deliveryMobilePhone)
                            ? ""
                            : "is-invalid")
                        }
                        onChange={value => {
                          multiRequestStore.setDeliveryMobilePhone(value);
                        }}
                        onCountryChange={value => {
                          multiRequestStore.setCountryCode(value);
                        }}
                        value={multiRequestStore.deliveryMobilePhone}
                      />
                    </RequiredInputWithLabel>
                  ) : null}
                </div>
                <div className="col-12 text-right mr-4">
                  <ButtonLink
                    style={{ fontSize: "0.875rem", padding: "0px" }}
                    onClick={() => multiRequestContactStore.setShowNewContactModal(true)}
                  >
                    Save Contact
                  </ButtonLink>
                </div>
              </React.Fragment>
            </MobileView>
          ) : null}
          {multiRequestStore.allowEitherContactMethod ? (
            <MobileView>
              <React.Fragment>
                <div className="col-12 pb-2">Please enter a valid e-mail address or USA/Canada phone number.</div>
                <div className="col-lg-4 col-12 form-group">
                  <BestLabel htmlFor="delivery-email">Email</BestLabel>
                  <Input
                    type="email"
                    className="form-control"
                    id="delivery-email"
                    value={multiRequestStore.deliveryEmail}
                    onChange={deliveryEmail => multiRequestStore.setDeliveryEmail(deliveryEmail.target.value)}
                  />
                </div>
                <div className="col-lg-4 col-12 form-group">
                  <BestLabel htmlFor="intl-tel-input-1">Mobile Phone</BestLabel>
                  <PhoneInputWithCountrySelect
                    placeholder="US/Canada Only"
                    defaultCountry={multiRequestStore.countryCode || "US"}
                    countries={["US", "CA"]}
                    international={false}
                    addInternationalOption={false}
                    countryCallingCodeEditable={false}
                    className={
                      "d-flex form-control " +
                      (multiRequestStore.deliveryMobilePhone &&
                      isValidPhoneNumber(multiRequestStore.deliveryMobilePhone)
                        ? ""
                        : "is-invalid")
                    }
                    onChange={value => {
                      multiRequestStore.setDeliveryMobilePhone(value);
                    }}
                    onCountryChange={value => {
                      multiRequestStore.setCountryCode(value);
                    }}
                    value={multiRequestStore.deliveryMobilePhone}
                  />
                </div>
                <div className="col-12 text-right mr-4">
                  <ButtonLink
                    style={{ fontSize: "0.875rem", padding: "0px" }}
                    onClick={() => multiRequestContactStore.setShowNewContactModal(true)}
                  >
                    Save Contact
                  </ButtonLink>
                </div>
              </React.Fragment>
            </MobileView>
          ) : null}
        </div>
      </>
    );
  }
}

export default inject("rootStore")(observer(MultiRequestFilters));
