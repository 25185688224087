import React from "react";
import { inject, observer } from "mobx-react";
import { DataTable, IconFormatter } from "best-common-react";
import EditContactPencilFormatter from "./EditContactPencilFormatter";
import DeleteContactPencilFormatter from "./DeleteContactPencilFormatter";
import HigherOrderComponent from "../../utilities/HigherOrderComponent";

let TrashFormatter = HigherOrderComponent(IconFormatter);
TrashFormatter.defaultProps = {
  icon: "fa-trash-alt",
  id: "contact-delete-formatter"
};
class ContactManagementTable extends React.Component {
  constructor(props) {
    super(props);
    const cols = [
      {
        key: "contactId",
        name: "",
        sortable: false,
        formatter: EditContactPencilFormatter,
        width: 35,
        locked: true
      },
      {
        key: "contactId1",
        name: "",
        sortable: false,
        formatter: DeleteContactPencilFormatter,
        width: 35,
        locked: true
      },
      {
        key: "firstName",
        name: "First Name",
        sortable: true
      },
      {
        key: "lastName",
        name: "Last Name",
        sortable: true
      },
      {
        key: "relation",
        name: "Relation",
        sortable: true
      },
      {
        key: "email",
        name: "Email",
        sortable: true
      },
      {
        key: "formatPhone",
        name: "Phone",
        sortable: true
      }
    ];
    this.state = { data: props.data, sortedData: props.data, cols: cols, selectedContact: null };
  }
  render() {
    const { contactManagementStore } = this.props.rootStore;
    return (
      <div className="mt-4">
        <DataTable
          id="selectContactTable"
          data={contactManagementStore.displayContacts}
          columns={this.state.cols}
          height={400}
          rowSelection={{
            showCheckbox: false,
            selectBy: {
              isSelectedKey: "isSelected"
            },
            enableShiftSelect: false,
            canSelect: false
          }}
          selectAll={false}
          sortFunc={contactManagementStore.setContactSortDirection}
          sortColumn={contactManagementStore.contactSortFilters["key"]}
          sortDirection={contactManagementStore.contactSortFilters["direction"]}
        />
      </div>
    );
  }
}

export default inject("rootStore")(observer(ContactManagementTable));
