import React from "react";
import { Accordion, AttributeHeader, AttributeValue } from "best-common-react";
import RequestDateFormatter from "../../elements/tables/RequestDateFormatter";
import styled from "styled-components";
import Date from "../../common/displays/Date";
import GameDisplay from "../../common/displays/GameDisplay";
import moment from "moment";
import { timezone } from "../../../utilities/comptixFormatter";

const Header = styled(AttributeHeader)`
  margin-bottom: -0.25rem;
`;

const Description = styled.div`
  color: #666666;
  font-size: 0.875rem;
`;

const Tickets = styled.span`
  font-size: 0.75rem;
  color: ${props => props.theme["dark-grey"]};
`;

const UserReservationsAccordionMobile = ({ index, data, ...rest }) => {
  let gameDate =
    data.date.tbd || !data.date.tz ? moment(data.date.gameDate).utc() : moment(data.date.gameDate).tz(data.date.tzId);
  let timeZone = timezone(gameDate.format("zz"));
  let ticketNumber = data.quantity === 1 ? "1 ticket" : `${data.quantity} tickets`;
  return (
    <div>
      <Accordion id={index} {...rest}>
        <Accordion.Header isToggle={true}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-md-between flex-wrap mb-1 mr-2 mt-2">
              <div className="mr-5 mr-md-0">
                <Date>{gameDate.format("MMM D")}</Date>
                <Description>{gameDate.format("ddd")}</Description>
              </div>
              <div>
                <GameDisplay>{data.game}</GameDisplay>
                <Description>{data.date.tbd ? "TBD" : gameDate.format("h:mm a") + " " + timeZone}</Description>
              </div>
            </div>
            <div>
              <Tickets>
                {ticketNumber} for {data.recipientFirstName} {data.recipientLastName}
              </Tickets>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            <Header>Venue</Header>
            <AttributeValue>{data.venue}</AttributeValue>
            <Header>Relation</Header>
            <AttributeValue>{data.relation}</AttributeValue>
            <Header>Section</Header>
            <AttributeValue>{data.sectionAndPrice}</AttributeValue>
            <Header>Value</Header>
            <AttributeValue>{`$${data.value}`}</AttributeValue>
            <Header>Request Date</Header>
            <AttributeValue>
              <RequestDateFormatter value={data.requestDate} />
            </AttributeValue>
            <Header>Comments</Header>
            <AttributeValue>{data.comments}</AttributeValue>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  );
};

export default UserReservationsAccordionMobile;
