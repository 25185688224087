import React from "react";
import { inject, observer } from "mobx-react";
import { FormColumn4Wide, BestDropdown, LightButton, BestLabel } from "best-common-react";
import EventDataCard from "../../EventDataCard";
import LoadingSpinner from "../../../loading/LoadingSpinner";

class EventDetailsFilters extends React.Component {
  render() {
    const { eventDetailsStore, authStore } = this.props.rootStore;
    let orgDropdown;

    let tmEventIds = eventDetailsStore.tmEventIds;
    if (!eventDetailsStore.event) {
      return <LoadingSpinner size={10} />;
    }

    if (authStore.isAdmin || authStore.isBoxOfficeAdmin) {
      orgDropdown = (
        <BestDropdown
          id="organization"
          title="Organization"
          options={eventDetailsStore.orgDropdown}
          value={eventDetailsStore.selectedOrg}
          onChange={value => eventDetailsStore.setSelectedOrg(value)}
        />
      );
    } else {
      orgDropdown = <div>&nbsp;</div>;
    }

    return (
      <div className="row form-group">
        <FormColumn4Wide>
          <EventDataCard
            game={eventDetailsStore.event}
            showVenue={false}
            eventId={tmEventIds}
            isGlobal={authStore.isGlobal}
          />
        </FormColumn4Wide>
        <FormColumn4Wide className="mt-0">{orgDropdown}</FormColumn4Wide>

        {!(authStore.isAdmin || authStore.isGlobal) ? null : eventDetailsStore.isLocked ? (
          <FormColumn4Wide className="col-lg-3 col-md-3 col-sm-12 mt-4">
            <LightButton onClick={() => eventDetailsStore.setShowUnlockGameModal(true)}>Unlock Game</LightButton>
          </FormColumn4Wide>
        ) : (
          <FormColumn4Wide className="col-lg-3 col-md-3 col-sm-12 mt-4">
            <LightButton onClick={() => eventDetailsStore.setShowLockGameModal(true)}>Lock Game</LightButton>
          </FormColumn4Wide>
        )}
      </div>
    );
  }
}

export default inject("rootStore")(observer(EventDetailsFilters));
